import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './components/ThemeContext';
import Home from './pages/Home';
import About from './pages/About';
import Team from './pages/Team';
import Product from './pages/Product';
import Investment from './pages/Investment';
import Header from './components/Header';
import Careers from './pages/Careers';

import ErlangDeveloper from './pages/careers/ErlangDeveloper';
import ProjectManager from './pages/careers/ProjectManager';
import BackEndDeveloper from './pages/careers/BackendDeveloper';
import FrontEndDeveloper from './pages/careers/FrontendDeveloper';
import QAEngineer from './pages/careers/QAEngineer'; 
import ITSupport from './pages/careers/ITSupport'; 
import GraphicDesigner from './pages/careers/GraphicDesigner'; 
import DevOpsEngineer from './pages/careers/DevOpsEngineer'; 
import MarketingSpecialist from './pages/careers/MarketingSpecialist'; 
import CFO from './pages/careers/CFO'; 

const App = () => {
  return (
    <ThemeProvider>
      <div className="w-screen h-screen flex flex-col">
        <Router>
          <Header className="h-16" />
          <main className="flex-grow overflow-y-auto pt-16">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/team" element={<Team />} />
              <Route path="/product" element={<Product />} />
              <Route path="/investment" element={<Investment />} />
              <Route path="/career" element={<Careers />} />
              <Route path="/career/erlang-developer" element={<ErlangDeveloper />} />
              <Route path="/career/project-manager" element={<ProjectManager />} />
              <Route path="/career/backend-developer" element={<BackEndDeveloper />} />
              <Route path="/career/frontend-developer" element={<FrontEndDeveloper />} />
              <Route path="/career/qa-engineer" element={<QAEngineer />} /> 
              <Route path="/career/it-support" element={<ITSupport />} /> 
              <Route path="/career/graphic-designer" element={<GraphicDesigner />} /> 
              <Route path="/career/devops-engineer" element={<DevOpsEngineer />} /> 
              <Route path="/career/marketing-specialist" element={<MarketingSpecialist />} /> 
              <Route path="/career/cfo" element={<CFO />} /> 
            </Routes>
          </main>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
