import React from 'react';
import ApplicationForm from '../../components/ApplicationForm';

const CFO = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-4xl font-bold mb-6">Chief Financial Officer (CFO)</h1>
    <h3 className="text-2xl mb-4">Location: Altamonte Springs, FL (On-Site)</h3>
    <h3 className="text-2xl mb-4">Salary: Competitive, based on experience    </h3>
    <h3 className="text-2xl mb-4">Positions Available: 1</h3>

    <h2 className="text-3xl font-bold mt-8 mb-4">Job Overview</h2>
    <p className="text-lg mb-6">
      We are seeking an experienced Chief Financial Officer (CFO) to oversee the financial operations of <strong>BitSoft, LLC</strong>. The CFO will lead the financial
      strategy, planning, and analysis, ensuring financial stability, compliance, and growth. In this role, the CFO will work closely with the CEO to align financial planning
      with business objectives and optimize resources. A key responsibility will also include auditing customer records to ensure compliance with licensing contracts.
    </p>

    <h2 className="text-3xl font-bold mt-8 mb-4">Key Responsibilities</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Oversee financial strategies and processes for BitSoft.</li>
      <li>Manage budgeting, forecasting, and financial reporting.</li>
      <li>Ensure compliance with financial regulations and reporting requirements.</li>
      <li>Audit customer records to ensure compliance with licensing contracts.</li>
      <li>Manage cash flow, investments, and financial risk strategies.</li>
      <li>Conduct financial audits and work with external auditors as needed.</li>
      <li>Oversee tax planning and ensure tax compliance.</li>
      <li>Collaborate with leadership to align financial planning with business objectives.</li>
      <li>Develop and implement financial controls and risk management frameworks.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Qualifications</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Proven experience as a CFO or in a senior financial management role.</li>
      <li>Expertise in financial planning, budgeting, and auditing.</li>
      <li>Knowledge of software licensing contracts and compliance management.</li>
      <li>Strong understanding of tax planning and regulatory compliance.</li>
      <li>Excellent leadership, communication, and analytical skills.</li>
      <li>Experience in the gaming or software industry is a plus.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Additional Requirements</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Work Authorization: Candidates must be legally authorized to work in the United States.</li>
      <li>Non-Compete and Confidentiality Agreement: Candidates must agree to sign a non-compete and confidentiality agreement upon hiring.</li>
      <li>On-Site Position: This is an on-site position, and candidates must be able to work from our Orlando, FL office.</li>
      <li>Availability: Candidates must be available to work weekends or holidays as needed to meet project demands.</li>
      <li>Smoke-Free Environment: We maintain a smoke-free workplace due to health concerns and to promote productivity.</li>
      <li>Drug and Alcohol-Free Policy: Candidates must pass a pre-employment drug screening and agree to maintain a drug and alcohol-free status while employed.</li>
      <li>Background Check: A background check will be conducted during the hiring process. Criminal records older than 7 years may be reviewed on a case-by-case basis.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">What We Offer</h2>
    <ul className="list-disc list-outside text-lg pl-[4rem]">
      <li>Competitive salary and benefits package.</li>
      <li>Opportunity to work with cutting-edge technology in a growing company.</li>
      <li>A collaborative and innovative work environment.</li>
      <li>The chance to make a significant impact on the future of our company and products.</li>
      <li>Potential for profit-sharing and future ownership percentage opportunities.</li>
    </ul>

    <ApplicationForm careerTitle="CFO" />
  </div>
);

export default CFO;
