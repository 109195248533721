import JonthanSwanson from '../assets/JSImage.png';
import SteveSwanson from '../assets/SSimage.webp';
import MikeSwanson  from '../assets/MRS.png';
import Professor from '../assets/drh.png';
// Add these new imports
import Logo from '../assets/BitSoftLogo.svg';

const teamMembers = [
  {
    id: 1,
    name: 'Steve Swanson',
    position: 'Founder & CEO',
    bio: `<p>
          Steven Swanson is an industry veteran with over 25 years of experience in online gaming and software development. 
          His journey began in the 1990s, where he co-founded PM Development, LLC, which went on to become the leading 
          online bingo platform from 2004 to 2006. With a strong background in programming, Steven has been the driving 
          force behind the development of innovative systems that cater to the evolving needs of the online gaming sector.
          </p><br /><p>
          A passionate advocate for fairness and transparency in gaming, Steven has dedicated his career to ensuring that 
          players are provided with provably fair experiences. His expertise spans not only the creation of gaming systems 
          but also the integration of complex accounting solutions that handle multiple currencies, including cryptocurrency, 
          with a focus on security and compliance.
          </p><br /><p>
          Steven’s leadership is built on decades of experience, coupled with a deep commitment to building products that 
          reflect his core values of integrity, security, and innovation. Over the years, he has fostered lasting 
          relationships with key collaborators, including David Best, with whom he has worked for over 25 years.
          </p>`,
    image: SteveSwanson
  },
  {
    id: 2,
    name: 'David Best',
    position: 'Chief Software Architect (CSA)',
    bio: `<p>
          David Best, with over 40 years of programming experience, serves as the Chief Software Architect at BitSoft. 
          Throughout his career, he has been a driving force behind the design and development of core technologies that 
          power scalable and reliable systems.
          </p><br /><p>
          For over 25 years, David has collaborated with BitSoft’s founder, applying his extensive expertise in programming 
          languages such as Erlang, C, C++, Object Pascal, Java, and SQL. His leadership has been instrumental in developing 
          BitSoft’s database architecture and backend systems, ensuring robust and secure solutions that meetgit the company's 
          high standards for performance and efficiency.
          </p><br /><p>
          David’s long-standing professional relationship with the founder has been a cornerstone of BitSoft’s technical direction, 
          helping to position the company for continued growth and success.
          </p>`,
    image: Logo  // Updated to use the imported image
  },
  {
    id: 3,
    name: 'Mike Swanson',
    position: 'Chief Technology Officer (CTO)',
    bio: `<p>
          Mike Swanson brings over 20 years of technical expertise to his role as Chief Technology Officer at BitSoft, LLC. A seasoned 
          professional in software architecture and system scalability, Mike has led multiple large-scale development projects across 
          the gaming and fintech industries. His deep knowledge of cloud infrastructure, distributed systems, and real-time transaction 
          processing has positioned him as a critical leader in BitSoft’s mission to deliver high-performance, secure software solutions.
          </p><br /><p>
          Prior to joining BitSoft, Mike worked on several high-profile technology initiatives, where he spearheaded the development of 
          platforms capable of handling millions of users. His ability to architect systems for both performance and reliability has made 
          him an invaluable asset to the company.
          </p><br /><p>
          In addition to his technical acumen, Mike is passionate about mentoring the next generation of developers and has been a vocal 
          advocate for fostering innovation and collaboration within tech teams. His leadership and forward-thinking approach continue to 
          drive BitSoft’s technological vision forward.
          </p>`,
    image: MikeSwanson  // Updated to use the imported image
  },
  {
    id: 4,
    name: 'Senior Statistical Consultant',
    position: 'Professor of Mathematics',
    bio: `<p>
          With over 35 years of experience in academia, I have specialized in applying advanced statistical theories and 
          mathematical models to a variety of complex problems. My academic career has been focused on ensuring accuracy and fairness 
          in systems where probability plays a critical role, which makes me an ideal consultant for BitSoft.
          </p><br /><p>
          In my role, I ensure that our game logic operates with precision, rigorously validating probability models to 
          deliver provably fair and transparent gaming experiences. My contributions help BitSoft maintain its commitment to offering 
          secure and trustworthy gaming software, ensuring all game mechanics are thoroughly tested and mathematically sound.
          </p><br /><p>
          <span style="font-size: smaller; color: gray;">Due to institutional policies and confidentiality protocols of our academic consultancy framework, the identity of the expert consultant involved in this project must remain undisclosed.</span>
          </p>`,
    image: Professor,
    audioFile: "drh.mp3"  // Added this line
  },
  {
    id: 5,
    name: 'Jonathan Swanson',
    position: 'Creative Director',
    bio: `<p>
    With a decade of experience in graphic and web design, along with eight years of managing public relations and a community of over 25,000 members, I bring a unique skill set to the role of Creative Director at BitSoft.</p><br /><p>
    In this position, I am dedicated to crafting a consistent and engaging design narrative across all our projects. My leadership will emphasize not only aesthetics but also the enhancement of community engagement and public relations through our designs. I strive to guide our team in producing work that resonates with users, strengthens our brand's presence, and effectively supports our broader communication objectives. I am excited for our clients to recognize the value and innovation I aim to deliver in every project.</p>`,
    image: JonthanSwanson,
    audioFile: "JS.mp3"  // Added this line
  },
];

export default teamMembers;
