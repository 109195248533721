import LOTTERY from '../assets/lottery.png';
import BINGO from '../assets/BINGO.png';
import KENO from '../assets/KENO.jpeg';
import TRIMORRA from '../assets/trimorra_logo.png';
import POKER from '../assets/Poker.jpg';

const teamMembers = [
  {
    id: 1,
    name: 'LOTTERY',
    position: 'Fair Play, Full Payouts, Every Time',
    bio: `<p>
          Our lottery games are designed to provide a fair, transparent, and exciting experience for players while 
          offering operators a reliable and scalable solution. Built with cutting-edge technology and designed with simplicity 
          in mind, our lottery games are easy to integrate and even easier for players to enjoy.
          </p>

          <h3 class="text-xl font-bold mt-8 mb-4">Key Features:</h3>
          
           <ul class="list-none mb-6">
            <li class="mb-4">
              <strong>Provably Fair:</strong>
              <p class="ml-4">
                All BitSoft lottery games use cryptographic algorithms to ensure each draw is random and verifiable. During ticket purchases, 
                a text file containing game data is processed through the SHAKE256 algorithm, and the resulting output is published after the 
                draw. This allows both operators and players to independently verify the fairness of the process, ensuring transparency and 
                trust in every game.
              </p>
            </li>

            <li class="mb-4">
              <strong>Customizable Gameplay:</strong>
              <p class="ml-4">
                Our lottery games can be tailored to fit the needs of operators. From prize structures to ticket formats, operators have full 
                control over customization, allowing them to create unique offerings for their player base.
              </p>
            </li>

            <li class="mb-4">
              <strong>Cross-Platform Compatibility:</strong>
              <p class="ml-4">
                Our lottery games are designed for seamless play across multiple devices, whether on desktop, mobile, or tablet. This ensures that players 
                can participate from anywhere, without sacrificing the quality of their experience.
              </p>
            </li>

            <li class="mb-4">
              <strong>Scalable for High Demand:</strong>
              <p class="ml-4">
                With the ability to support thousands of simultaneous players, our lottery games are built to handle large player pools and high-volume 
                draws. Operators can rely on BitSoft’s platform to perform smoothly, even during peak times.
              </p>
            </li>

            <li class="mb-4">
              <strong>Personalized Lottery Pools</strong>
              <p class="ml-4">
                A key feature of BitSoft’s lottery games is the ability for players to create Personalized Lottery Pools. This allows groups of players 
                to collaborate, pooling their tickets and sharing potential winnings. Our platform automates the disbursement of prizes within each pool, 
                making it easy for players to enjoy a shared lottery experience.
              </p>
            </li>

            <li class="mb-4">
              <strong>Jackpots and Super Jackpots</strong>
              <p class="ml-4">
                Our lottery games feature Traditional Jackpots, giving players the excitement of winning large prizes. Unlike many lotteries that 
                inflate jackpot amounts due to annuity options, BitSoft’s jackpots reflect the actual lump sum payout—what you see is what you win, 
                with no need for extended payment plans. Additionally, some games include a Super Jackpot, triggered when the draw occurs in perfect 
                numeric order, offering even bigger rewards for lucky players.
              </p>
            </li>
          </ul>`,
    image: LOTTERY
  },
  {
    id: 2,
    name: 'BINGO',
    position: 'Your Bingo, Your Way—Flexible, Fun, and Scalable',
    bio: `<p>
            BitSoft offers a comprehensive range of Bingo games, including traditional US and UK styles, alongside customized variations like our popular 3-Up game. 
            Our Bingo software provides operators with flexible options for engaging players, whether they prefer classic gameplay or something new and exciting.
          </p>

          <h3 class="text-xl font-bold mt-8 mb-4">Key Features:</h3>

          <ul class="list-none mb-6">
            <li class="mb-4">
              <strong>Traditional US and UK Styles:</strong>
              <p class="ml-4">
                Players can enjoy the familiarity of classic Bingo in both US (75-ball) and UK (90-ball) formats. These traditional styles provide a reliable and 
                well-loved gaming experience for Bingo enthusiasts worldwide.
              </p>
            </li>

            <li class="mb-4">
              <strong>Custom Games like 3-Up:</strong>
              <p class="ml-4">
                In addition to traditional formats, BitSoft offers customized Bingo games like 3-Up, providing operators with exciting new ways to engage their players. 
                These variations keep the gameplay fresh and appealing for both long-time fans and newcomers.
              </p>
            </li>

            <li class="mb-4">
              <strong>Flexible and Customizable:</strong>
              <p class="ml-4">
                Operators can easily customize game configurations, payout structures, and ticket designs to meet the preferences of their player base. 
                This flexibility ensures that Bingo offerings remain competitive and adaptable to different market needs.
              </p>
            </li>
          </ul>

          <h3 class="text-xl font-bold mb-4">Why Choose BitSoft Bingo?</h3>
          <p>
            BitSoft’s Bingo software combines classic gameplay with innovative variations, giving operators the tools to offer a flexible, scalable, 
            and engaging Bingo experience. Whether players prefer traditional Bingo or are looking for something new, BitSoft’s Bingo provides a comprehensive 
            solution that keeps them coming back for more.
          </p>`,
    image: BINGO, 
  },
  {
    id: 3,
    name: 'KENO',
    position: 'Multiple Ways to Play, Multiple Ways to Win',
    bio: `<p>
            We provide a dynamic and engaging Keno experience with a variety of play styles and unique features. From traditional 1-spot to 13-spot games, 
            including a progressive jackpot option, to innovative betting features like Left/Right and Top/Bottom, our Keno software is designed to keep players excited 
            while offering operators a flexible and scalable solution.
          </p>

          <h3 class="text-xl font-bold mt-8 mb-4">Key Features:</h3>

          <ul class="list-none mb-6">
            <li class="mb-4">
              <strong>1-Spot to 13-Spot Play:</strong>
              <p class="ml-4">
                Players can choose anywhere from 1 to 13 numbers, with the 13-spot game offering a chance to win the progressive jackpot. This wide range of betting options 
                ensures that both casual players and high-stakes enthusiasts can find the right game for their style.
              </p>
            </li>

            <li class="mb-4">
              <strong>Unique Betting Options:</strong>
              <p class="ml-4">
                In addition to traditional Keno, players can participate in exciting side bets, including Left/Right, Top/Bottom, Even/Odd, and the Edge. 
                The Edge game stands out by offering a chance at the progressive jackpot, adding more excitement to the gameplay.
              </p>
            </li>

            <li class="mb-4">
              <strong>Progressive Jackpot:</strong>
              <p class="ml-4">
                The 13-spot and Edge games feature a progressive jackpot, making each round more thrilling as the prize pool grows. This feature 
                encourages repeat play, boosting engagement while giving operators the ability to offer life-changing payouts.
              </p>
            </li>

            <li class="mb-4">
              <strong>Flexible and Customizable:</strong>
              <p class="ml-4">
                BitSoft’s Keno software allows operators to customize payout structures, game configurations, and betting options. This flexibility ensures 
                the game can be tailored to meet the needs of different player demographics and market demands.
              </p>
            </li>
          </ul>

          <h3 class="text-xl font-bold mb-4">Why Choose BitSoft Keno?</h3>
          <p>
            BitSoft’s Keno software delivers variety and excitement with a range of play styles and the thrill of a progressive jackpot. Designed for operators 
            who want to offer players more than just a standard Keno experience, BitSoft’s Keno brings flexibility, scalability, and engaging gameplay to any platform.
          </p>`,
    image: KENO,
  },
  {
    id: 4,
    name: 'TRIMORRA',
    position: 'Odd or Even: Let the Battle Begin',
    bio: ` <p>
            <strong>Trimorra</strong> is a unique three-player variant of the classic "odds and evens" game, designed for players who enjoy 
            the thrill of pure chance. In each round, players roll a random number, and the game determines whether the number is even or odd. 
            The winner is the player whose number has the opposite parity (even/odd) from the other two. If all players have the same parity, 
            the game re-rolls until a winner emerges.
          </p>

          <h3 class="text-xl font-bold mt-8 mb-4">Key Features:</h3>
          
           <ul class="list-none mb-6">
            <li class="mb-4">
              <strong>Bracketed Tournaments:</strong>
              <p class="ml-4">
                Trimorra’s gameplay is structured into tiered brackets. Players advance by winning rounds, and with each tier, the competition intensifies. Winners move forward until an overall winner is determined in multi-tier games.
              </p>
            </li>

            <li class="mb-4">
              <strong>Progressive Jackpot:</strong>
              <p class="ml-4">
                Trimorra offers an exciting progressive jackpot feature. If the two non-winners in a group end up with the exact same number, all three players in the group win a share of the jackpot. In multi-tier games, if two or more groups hit the jackpot, the prize is split among all the jackpot winners within that game. There is only one jackpot per game, making each round thrilling and adding extra anticipation for players.
              </p>
            </li>

            <li class="mb-4">
              <strong>Provably Fair and Secure:</strong>
              <p class="ml-4">
                Every game of Trimorra is provably fair, with cryptographically secure random number generation powered by the SHAKE256 algorithm. After each game, players are given access to the secret key used, allowing them to verify that the results were not manipulated. This ensures transparency, security, and fairness in every round.
              </p>
            </li>

            <li class="mb-4">
              <strong>Pure Luck:</strong>
              <p class="ml-4">
                Trimorra is a game driven purely by luck—no strategies, no special skills required. Every player starts with equal chances, and one-third 
                of participants are guaranteed to win back at least their original buy-in, adding an extra layer of excitement to every round.
              </p>
            </li>
          </ul>`,
    image: TRIMORRA,
  },
  {
    id: 5,
    name: 'POKER',
    position: 'True Vegas Style Poker',
    bio: `<p>
            BitSoft brings the authentic experience of a casino poker room to the online world with <strong>True Vegas Style Poker</strong>. 
            Our poker software is designed to replicate the environment and atmosphere of playing live in a Vegas poker room. From the shuffle to the 
            showdown, every aspect of the game is treated as if you were sitting at a real casino table.
          </p>

          <h3 class="text-xl font-bold mt-8 mb-4">Key Features:</h3>

          <ul class="list-none mb-6">
            <li class="mb-4">
              <strong>Real Casino Experience:</strong>
              <p class="ml-4">
                Unlike traditional poker software, BitSoft’s poker simulates the real-world dynamics of a casino poker room. Players can expect familiar mechanics, 
                from card handling to the pace of play, creating a more immersive and genuine poker experience.
              </p>
            </li>

            <li class="mb-4">
              <strong>No Peeking at Folded Hands:</strong>
              <p class="ml-4">
                Just like in a live poker room, once a player folds, their hand remains hidden from the other players. This preserves the integrity of the game, 
                ensuring that bluffs and folds remain part of the strategy, just as they would in a real casino.
              </p>
            </li>

            <li class="mb-4">
              <strong>Dynamic Table Management:</strong>
              <p class="ml-4">
                Our software mirrors the management of live poker tables, from seating arrangements to bet structures, adapting to player behavior in real time. 
                The system is designed to handle cash games, tournaments, and other variants with the same level of authenticity found in brick-and-mortar casinos.
              </p>
            </li>

            <li class="mb-4">
              <strong>Scalable for Large Tournaments:</strong>
              <p class="ml-4">
                BitSoft’s poker platform is built to scale, making it capable of supporting small casual games or large, multi-table tournaments. Operators 
                can offer their players the excitement of tournament poker, with software that adjusts seamlessly to the number of participants.
              </p>
            </li>

            <li class="mb-4">
              <strong>Secure and Fair:</strong>
              <p class="ml-4">
                Using provably fair algorithms, BitSoft’s poker platform ensures that every hand is dealt with complete randomness and security, 
                giving both players and operators confidence in the integrity of the game.
              </p>
            </li>
          </ul>

          <h3 class="text-xl font-bold mb-4">Why Choose BitSoft Poker?</h3>
          <p>
            By replicating the real casino experience, BitSoft’s <strong>True Vegas Style Poker</strong> offers players and operators an authentic, scalable, 
            and secure poker platform that brings the thrill of Vegas to the online world.
          </p>`,
    image: POKER
  },
];

export default teamMembers;
