import React, { useState, useRef, useEffect, useCallback } from 'react';
import TeamMembers from '../components/TeamMembers';
import AudioPlayer from '../components/AudioPlayer';
import NeumorphicCard from '../components/NeumorphicCard';
import PageBanner from '../components/PageBanner';

const TruncatedBio = ({ bio }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const bioRef = useRef(null);

  useEffect(() => {
    if (bioRef.current) {
      setIsTruncated(bioRef.current.scrollHeight > bioRef.current.clientHeight);
    }
  }, [bio]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="text-left">
      <div
        ref={bioRef}
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isExpanded ? 'max-h-full' : 'max-h-[7.5em]'
        } ${
          !isExpanded && isTruncated
            ? 'bg-gradient-to-b from-black to-transparent bg-clip-text text-transparent'
            : ''
        }`}
        style={{ WebkitLineClamp: isExpanded ? 'unset' : '5', WebkitBoxOrient: 'vertical', display: '-webkit-box' }}
        dangerouslySetInnerHTML={{ __html: bio }}
      />
      {isTruncated && (
        <button
          onClick={toggleExpand}
          className="text-blue-600 hover:text-blue-800 mt-2 focus:outline-none"
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  );
};

const Team = ({ isPreview = false }) => {
  const [activePlayerId, setActivePlayerId] = useState(null);

  const handlePlaybackUpdate = useCallback((playerId, isPlaying, currentTime) => {
    console.log(`Playback update for ${playerId}: isPlaying=${isPlaying}, currentTime=${currentTime}`);
  }, []);

  const handlePlay = useCallback((playerId) => {
    setActivePlayerId(playerId);
    console.log(`Playing audio for ${playerId}`);
  }, []);

  const previewContent = (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-5xl mb-4">Our Team</h1>
      <p className="text-xl text-center max-w-2xl">
        Meet the brilliant minds behind BitSoft's innovative gaming solutions.
      </p>
    </div>
  );

  const fullContent = (
    <div className="bg-white">
      <PageBanner
        title="Our Team"
        description="Meet the brilliant minds behind BitSoft's innovative gaming solutions."
      />

      <div className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {TeamMembers.map(member => (
            <NeumorphicCard key={member.id} className="relative">
              {member.audioFile && (
                <div className="absolute top-12 right-12 z-10">
                  <AudioPlayer 
                    fileName={member.audioFile}
                    onPlaybackUpdate={handlePlaybackUpdate} 
                    playerId={`player-${member.id}`}
                    isActivePlayer={activePlayerId === `player-${member.id}`}
                    onPlay={handlePlay}
                  />
                </div>
              )}
              <div className="relative z-0">
                <img 
                  src={member.image || 'path-to-placeholder-image.png'} 
                  alt={member.name} 
                  className="w-32 h-32 rounded-full mx-auto mb-4" 
                />
                <h2 className="text-2xl font-bold text-center mb-2">{member.name}</h2>
                <p className="text-center text-gray-600 mb-2">{member.position}</p>
                <TruncatedBio bio={member.bio} />
              </div>
            </NeumorphicCard>
          ))}
        </div>
      </div>
    </div>
  );

  return isPreview ? previewContent : fullContent;
};

export default Team;
