import React from 'react';
import ApplicationForm from '../../components/ApplicationForm';

const GraphicDesigner = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-4xl font-bold mb-6">Graphic Designer</h1>
    <h3 className="text-2xl mb-4">Location: Altamonte Springs, FL (On-Site)</h3>
    <h3 className="text-2xl mb-4">Salary: Competitive, based on experience</h3>
    <h3 className="text-2xl mb-4">Positions Available: 2</h3>

    <h2 className="text-3xl font-bold mt-8 mb-4">Job Overview</h2>
    <p className="text-lg mb-6">
      We are seeking two versatile and experienced Graphic Designers to handle both game/website design and marketing materials. In this role, you
      will create visual assets for our games while also designing promotional materials for marketing campaigns. You will work closely with the UI/UX
      Designer and marketing team to ensure consistency in branding and visual elements across all platforms, both in-game and externally.
      Candidates must have several years of experience and provide a portfolio showcasing their work.
    </p>

    <h2 className="text-3xl font-bold mt-8 mb-4">Key Responsibilities</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Design visual assets for games, websites, and marketing campaigns, ensuring consistency across platforms.</li>
      <li>Collaborate with the UI/UX Designer to create user-friendly interfaces and in-game assets.</li>
      <li>Develop promotional materials for marketing campaigns, social media, and online ads.</li>
      <li>Ensure that the visual identity of the game aligns with the branding used in marketing efforts.</li>
      <li>Manage multiple design projects and meet deadlines across both product and marketing teams.</li>
      <li>Stay up-to-date with design trends and best practices to create fresh and engaging content.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Qualifications</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Several years of experience as a Graphic Designer, with a strong portfolio showcasing both digital product design and marketing materials.</li>
      <li>Proficiency in design tools such as Adobe Creative Suite (Photoshop, Illustrator, InDesign) or similar.</li>
      <li>Strong understanding of typography, color theory, and visual composition.</li>
      <li>Experience with branding, creating digital assets, and marketing design.</li>
      <li>Ability to manage multiple projects simultaneously and meet deadlines.</li>
      <li>Strong communication and collaboration skills, with experience working with cross-functional teams.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Additional Requirements</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Work Authorization: Candidates must be legally authorized to work in the United States.</li>
      <li>Non-Compete and Confidentiality Agreement: Candidates must agree to sign a non-compete and confidentiality agreement upon hiring.</li>
      <li>On-Site Position: Must work from our Altamonte Springs, FL office.</li>
      <li>Availability: Must be available to work weekends or holidays as needed to meet project demands.</li>
      <li>Smoke-Free Environment: Candidates must maintain a smoke-free status while employed.</li>
      <li>Drug and Alcohol-Free Policy: Pre-employment screening required, and employees must agree to maintain a drug and alcohol-free status while employed.</li>
      <li>Background Check: A background check will be conducted during the hiring process. Criminal records older than 7 years may be reviewed on a case-by-case basis.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">What We Offer</h2>
    <ul className="list-disc list-outside text-lg pl-[4rem]">
      <li>Competitive salary and benefits package.</li>
      <li>Opportunity to work with cutting-edge technology in a growing company.</li>
      <li>A collaborative and innovative work environment.</li>
      <li>Potential for profit-sharing and future ownership percentage opportunities.</li>
    </ul>

    <ApplicationForm careerTitle="Graphic Designer" />
  </div>
);

export default GraphicDesigner;
