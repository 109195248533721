import React, { createContext, useState, useContext } from 'react';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(false);

  const toggleTheme = (dark) => {
    setIsDark(dark);
  };

  const theme = {
    isDark,
    toggleTheme,
    background: isDark ? 'black' : 'white',
    text: isDark ? 'white' : 'black',
    invertedBackground: isDark ? 'white' : 'black',
    invertedText: isDark ? 'black' : 'white',
    nonHomeTheme: {
      background: 'white',
      text: 'black',
      invertedBackground: 'black',
      invertedText: 'white',
    },
  };

  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  );
};
