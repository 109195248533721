import React from 'react';
import Wavify from 'react-wavify';

const PageBanner = ({ title, description, ctaText, ctaLink }) => {
  return (
    <div className="bg-[#909DA3] text-black py-20 px-4 relative">
      <div className="container mx-auto text-center">
        <h1 className="text-5xl font-bold mb-4">{title}</h1>
        <p className="text-xl mb-8">{description}</p>
        {ctaText && ctaLink && (
          <a
            href={ctaLink}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white text-black px-8 py-4 rounded-full text-lg font-semibold hover:bg-gray-200 transition duration-300 shadow-lg"
          >
            {ctaText}
          </a>
        )}
      </div>
      <Wavify
        fill="#FFFFFF"
        paused={false}
        options={{
          height: 20,
          amplitude: 15,
          speed: 0.15,
          points: 4,
        }}
        className="absolute bottom-[-1px] left-0 right-0 w-full h-[60px]"
      />
    </div>
  );
};

export default PageBanner;
