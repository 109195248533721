import React from 'react';
import ApplicationForm from '../../components/ApplicationForm';

const QAEngineer = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-4xl font-bold mb-6">QA Engineer</h1>
    <h3 className="text-2xl mb-4">Location: Altamonte Springs, FL (On-Site)</h3>
    <h3 className="text-2xl mb-4">Salary: Competitive, based on experience</h3>
    <h3 className="text-2xl mb-4">Positions Available: 2</h3>

    <h2 className="text-3xl font-bold mt-8 mb-4">Job Overview</h2>
    <p className="text-lg mb-6">
      We are seeking two skilled QA Engineers to join our team. Quality Assurance is critical to ensuring that our products are free of bugs and issues
      before they reach users. In this role, you will be responsible for conducting both manual and automated testing across different platforms and
      use cases, ensuring comprehensive and thorough testing to reduce the risk of costly fixes after launch.
    </p>

    <h2 className="text-3xl font-bold mt-8 mb-4">Key Responsibilities</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Perform manual and automated testing to identify bugs, performance issues, and defects.</li>
      <li>Develop and maintain test cases, test plans, and automated test scripts.</li>
      <li>Ensure that all products meet quality standards before release.</li>
      <li>Collaborate with developers to understand project requirements and provide feedback on potential risks.</li>
      <li>Conduct cross-platform testing to ensure functionality on various devices and operating systems.</li>
      <li>Document and report any issues, defects, and potential improvements.</li>
      <li>Monitor and improve test coverage to ensure thorough validation of features and functionality.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Qualifications</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Proven experience as a QA Engineer, with expertise in both manual and automated testing.</li>
      <li>Strong knowledge of testing methodologies, tools, and best practices.</li>
      <li>Experience with test automation tools (e.g., Selenium, Cypress) and scripting languages (e.g., Python, JavaScript).</li>
      <li>Familiarity with cross-platform testing for desktop and mobile applications.</li>
      <li>Excellent problem-solving and analytical skills.</li>
      <li>Strong communication skills and ability to collaborate effectively with developers and team members.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Preferred Skills</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Experience working in an agile environment with CI/CD pipelines.</li>
      <li>Familiarity with bug tracking tools (e.g., Jira, Bugzilla) and version control systems (e.g., Git).</li>
      <li>Knowledge of performance and security testing practices.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Additional Requirements</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Work Authorization: Candidates must be legally authorized to work in the United States.</li>
      <li>Non-Compete and Confidentiality Agreement required upon hiring.</li>
      <li>On-Site Position: Must work from our office in Altamonte Springs, FL.</li>
      <li>Availability for weekends or holidays as needed to meet project demands.</li>
      <li>Smoke-Free Environment: Candidates must maintain a smoke-free status while employed.</li>
      <li>Drug and Alcohol-Free Policy: Pre-employment screening required.</li>
      <li>Background Check required, reviewed on a case-by-case basis.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">What We Offer</h2>
    <ul className="list-disc list-outside text-lg pl-[4rem]">
      <li>Competitive salary and benefits package.</li>
      <li>Opportunity to work with cutting-edge technology in a growing company.</li>
      <li>A collaborative and innovative work environment.</li>
      <li>Potential for profit-sharing and future ownership percentage opportunities.</li>
    </ul>

    <ApplicationForm careerTitle="QA Engineer" />
  </div>
);

export default QAEngineer;
