import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import AudioPlayer from '../components/AudioPlayer';
import NeumorphicCard from '../components/NeumorphicCard';
import PageBanner from '../components/PageBanner';

const ListCard = ({ children }) => (
  <div className="bg-white p-6 rounded-lg shadow-[5px_5px_10px_rgba(0,0,0,0.1),_-5px_-5px_10px_rgba(255,255,255,0.8)] mb-4">
    {children}
  </div>
);

const About = ({ isPreview = false }) => {
  const [activePlayerId, setActivePlayerId] = useState(null);

  const handlePlaybackUpdate = useCallback((playerId, isPlaying, currentTime) => {
    // This function is kept for future use if needed
    console.log(`Player ${playerId}: isPlaying=${isPlaying}, currentTime=${currentTime}`);
  }, []);

  const handlePlay = useCallback((playerId) => {
    setActivePlayerId(playerId);
  }, []);

  const previewContent = (
    <div className="flex flex-col items-center justify-center h-screen p-6">
      <h1 className="text-5xl font-bold mb-4">About BitSoft</h1>
      <p className="text-xl text-center max-w-2xl mb-6">
        BitSoft is a leading software company specializing in innovative online casino solutions. We combine cutting-edge technology with mathematical 
        precision to deliver unparalleled software for online casino experiences.</p>
      <Link 
        to="/about" 
        className="bg-black text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-opacity-80 transition duration-300"
      >
        Learn More
      </Link>
    </div>
  );

  const fullContent = (
    <div className="bg-white">
      <PageBanner
        title="About BitSoft"
        description="Transforming Online Casino Software"
      />

      <div className="container mx-auto px-4 py-16">
        <div className="flex flex-row items-center justify-center mb-8">
          <h2 className="text-4xl font-bold mr-4 text-black">Mission Statement</h2>
          <AudioPlayer 
            fileName="About-Mission.mp3" 
            onPlaybackUpdate={handlePlaybackUpdate} 
            playerId="player1"
            isActivePlayer={activePlayerId === "player1"}
            onPlay={handlePlay}
          />
        </div>
        <NeumorphicCard>
          <p className="text-lg mb-6">
            We are dedicated to transforming the online casino software industry by providing cutting-edge solutions to operators. Our mission is 
            to empower operators with innovative tools that ensure fairness, transparency, responsible gaming, elevating the player experience 
            and setting new industry standards. By focusing on innovation, fairness, and responsible gaming, we aim to create a gaming environment 
            where both operators and players thrive.
          </p>
          <h3 className="text-2xl font-bold mb-4">Our approach is multifaceted:</h3>
          <div className="grid grid-cols-1 gap-4">
            {[
              { title: "Innovative Technology", content: "We develop state-of-the-art software that enables operators to host games that are not only exciting but also verifiably fair. Our proprietary algorithms guarantee that every spin, deal, and roll is truly random and immune to manipulation." },
              { title: "Player-Centric Design", content: "Our software is designed with the player in mind. We prioritize intuitive interfaces, clear and honest information on odds and payouts, and provide operators with the tools to implement responsible gaming features." },
              { title: "Transparency", content: "We believe in complete openness. Our systems are designed to be publicly auditable, allowing operators, players, and regulators to verify the fairness of the games. We are setting a new benchmark for transparency in the online casino industry." },
              { title: "Ethical Business Practices", content: "We reject exploitative tactics common in the gambling industry. Our platform discourages hidden fees, misleading promotions, and aggressive marketing targeting vulnerable individuals. We help operators succeed by creating value for their players, not through exploitation." },
              { title: "Responsible Gambling", content: "Our commitment to responsible gambling is reflected in the resources we provide, including tools for problem gambling, self-exclusion policies, and game design principles that discourage addictive behavior." }
            ].map((item, index) => (
              <ListCard key={index}>
                <h4 className="text-xl font-semibold mb-2">{item.title}</h4>
                <p>{item.content}</p>
              </ListCard>
            ))}
          </div>
        </NeumorphicCard>

        <h2 className="text-4xl font-bold mb-8 text-center text-black">Our Vision</h2>
        <NeumorphicCard>
          <p className="text-lg mb-6">
            Envisioning a future where online casino software is synonymous with fairness, transparency, and responsible gaming. Our vision is to be at the forefront of transforming the industry, offering innovative solutions that empower operators to create a secure and trustworthy gaming environment.
          </p>
          <p className="text-lg mb-6">
            In this future, every aspect of online casino software will prioritize the well-being of players and the operational success of casino operators. We foresee a landscape where:
          </p>
          <div className="grid grid-cols-1 gap-4">
            {[
              "Provably fair algorithms are the standard, allowing players to verify the integrity of every game with confidence.",
              "Real-time odds and payout information are transparent and easily accessible, empowering players to make informed decisions.",
              "Responsible gaming tools are fully integrated, offering seamless access to self-exclusion deposit limits, and reality checks.",
              "Data analytics are utilized ethically to protect at-risk players while avoiding exploitative practices.",
              "Regulatory compliance is embedded into the software's core, helping operators navigate evolving legal standards across jurisdictions."
            ].map((item, index) => (
              <ListCard key={index}>
                <p className="text-lg">{item}</p>
              </ListCard>
            ))}
          </div>
        </NeumorphicCard>

        <h2 className="text-4xl font-bold mb-8 text-center text-black">Our Approach</h2>
        <NeumorphicCard>
          <p className="text-lg mb-6">
            Our company believes that a comprehensive approach is essential to delivering online casino software that meets the highest standards of integrity and performance. We focus on four key principles:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {[
              { title: "Education", content: "We provide operators with in-depth resources and documentation, empowering them to educate their players on how the software works and how fairness is ensured." },
              { title: "Transparency", content: "Our systems are built to be fully verifiable, allowing operators to offer games where players and regulators can independently confirm fairness and accuracy." },
              { title: "Responsible Gaming", content: "We develop tools to support strict self-exclusion policies and provide resources for operators to promote responsible gambling practices, ensuring the well-being of players." },
              { title: "Innovation", content: "Led by our founder, Steve Swanson, our expert team of developers and mathematicians continuously push the boundaries of what's possible in online casino software, delivering cutting-edge solutions that define the future of the industry." }
            ].map((item, index) => (
              <ListCard key={index}>
                <h4 className="text-xl font-semibold mb-2">{item.title}</h4>
                <p>{item.content}</p>
              </ListCard>
            ))}
          </div>
        </NeumorphicCard>

        <h2 className="text-4xl font-bold mb-8 text-center text-black">Our Team</h2>
        <NeumorphicCard>
          <div className="text-lg mb-6">
            <p className="mb-4">
              At the heart of BitSoft's innovation and success is our exceptional team of industry veterans, cutting-edge developers, and visionary leaders. With decades of combined experience in online casino operations, software development, and mathematical modeling, our team brings a wealth of knowledge and expertise to every aspect of our operation.
            </p>
            <p className="mb-4">
              From pioneering new technologies to setting new standards in fair play and responsible gaming, our team is dedicated to pushing the boundaries of what's possible in online casino software. We've assembled a diverse group of talents, including:
            </p>
            <ul className="list-disc pl-12 mb-4">
              <li>Seasoned casino industry experts</li>
              <li>Innovative software engineers and architects</li>
              <li>Skilled mathematicians and data scientists</li>
              <li>Experienced compliance and security specialists</li>
            </ul>
            <p className="mb-4">
              Together, we're not just building software; we're shaping the future of online casino operations. Our team's passion for excellence and commitment to ethical practices drives us to deliver solutions that benefit both operators and players alike.
            </p>
            <p>
              Curious about the minds behind BitSoft's groundbreaking technologies? Want to learn more about our leadership and the expertise driving our success?
            </p>
          </div>
          <div className="text-center">
            <Link 
              to="/team" 
              className="inline-block bg-black text-white px-8 py-3 rounded-full text-lg font-semibold hover:transition duration-300 shadow-[5px_5px_10px_rgba(0,0,0,0.3)] hover:shadow-[10px_10px_20px_rgba(0,0,0,0.5)] hover:scale-105 active:shadow-[inset_5px_5px_10px_rgba(0,0,0,0.5)] active:scale-95"
            >
              Meet the Innovators Behind BitSoft
            </Link>
          </div>
        </NeumorphicCard>

        <h2 className="text-4xl font-bold mb-8 text-center text-black">Our Commitment</h2>
        <NeumorphicCard>
          <p className="text-lg mb-6">
            We are dedicated to leading the way in player safety within the online casino industry by delivering state-of-the-art software solutions. Our commitment is to empower operators to create a gaming environment that is not only exciting but also fair, transparent, and responsible. By prioritizing ethical practices and innovative technology, we are shaping the future of online casino software, proving that thrilling gaming experiences can go hand in hand with player well-being and integrity.
          </p>
        </NeumorphicCard>
      </div>
    </div>
  );

  return isPreview ? previewContent : fullContent;
};

export default About;