import React, { useEffect, useRef } from 'react';
import Wavify from 'react-wavify';
import PalmIsland from '../assets/PalmIsland.svg';
import About from './About';
import Team from './Team';
import Product from './Product';
import Investment from './Investment';
import { useTheme } from '../components/ThemeContext';
import Careers from './Careers';
import Logo from '../components/logo';

const getColors = (id) => {
  return id % 2 === 0
    ? { bgColor: 'bg-white', textColor: 'text-black' }
    : { bgColor: 'bg-black', textColor: 'text-white' };
};

const Home = () => {
  const { toggleTheme } = useTheme();
  const sectionRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const callback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const sectionId = parseInt(entry.target.getAttribute('data-section-id'));
          toggleTheme(sectionId % 2 !== 0);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    const currentRefs = sectionRefs.current;

    currentRefs.forEach(section => {
      if (section) observer.observe(section);
    });

    return () => {
      if (observer) {
        currentRefs.forEach(section => {
          if (section) observer.unobserve(section);
        });
      }
    };
  }, [toggleTheme]);

  const sections = [
    {
      id: 1,
      title: 'Home',
      content: () => (
        <div className="h-full w-full flex flex-col items-center justify-center gap-10 translate-y-[-30px]">
          <Logo color={toggleTheme ? 'white' : 'black'} width="300px" />
          <h2 className="text-4xl text-center">Changing the World of Online Casinos</h2>
        </div>      ),
    },
    {
      id: 2,
      title: 'About Us',
      content: <About isPreview={true} />,
    },
    {
      id: 3,
      title: 'Team',
      content: <Team isPreview={true} />,
    },
    {
      id: 4,
      title: 'Our Products',
      content: <Product isPreview={true} />,
    },
    {
      id: 5,
      title: 'Investment',
      content: <Investment isPreview={true} />,
    },
    {
      id: 6,
      title: 'Careers',
      content: <Careers isPreview={true} />,
    },
  ];

  return (
    <div className="h-full overflow-y-auto snap-y snap-mandatory relative">
      {sections.map((section, index) => {
        const { bgColor, textColor } = getColors(section.id);
        return (
          <section
            key={section.id}
            data-section-id={section.id}
            data-bgcolor={bgColor}
            ref={el => sectionRefs.current[index] = el}
            className={`${bgColor} ${textColor} h-full w-full flex flex-col justify-center items-center snap-start relative overflow-hidden`}
          >
            <div className="z-10 text-center">
              {typeof section.content === 'function' ? section.content() : section.content}
            </div>
            {index !== sections.length - 1 && (
              <>
                <Wavify
                  fill={bgColor === 'bg-black' ? '#FFFFFF' : '#000000'}
                  paused={false}
                  options={{
                    height: 20,
                    amplitude: 15,
                    speed: 0.15,
                    points: 4,
                  }}
                  className="absolute bottom-[-5px] left-0 right-0 w-full h-[60px]"
                />
                <img
                  src={PalmIsland}
                  alt="Palm Island"
                  className={`absolute bottom-0 right-[5vw] h-[7vh] animate-smooth-up-down ${bgColor === 'bg-black' ? 'filter invert' : ''}`}
                />
              </>
            )}
          </section>
        );
      })}
    </div>
  );
};

export default Home;