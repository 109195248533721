import React, { useState, useEffect, useRef, useCallback } from 'react';

const PlayButton = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
        <path fill-rule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clip-rule="evenodd" />
    </svg>
  
);

const StopButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
    <path fillRule="evenodd" d="M4.5 7.5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-9Z" clipRule="evenodd" />
  </svg>
);

const AudioPlayer = ({ fileName, onPlaybackUpdate, playerId, isActivePlayer, onPlay }) => {
  const [audioFiles, setAudioFiles] = useState({});
  const [currentAudio, setCurrentAudio] = useState(null);
  const audioRef = useRef(null);

  useEffect(() => {
    const importAll = (r) => {
      let files = {};
      r.keys().forEach((item) => { files[item.replace('./', '')] = r(item); });
      return files;
    }
    
    const audioModules = importAll(require.context('../assets/readings', false, /\.(mp3|wav)$/));
    setAudioFiles(audioModules);
  }, []);

  const playAudio = useCallback(() => {
    if (audioFiles[fileName]) {
      setCurrentAudio(audioFiles[fileName]);
      onPlay(playerId);
      onPlaybackUpdate(playerId, true, 0);
    } else {
      console.error(`Audio file ${fileName} not found`);
    }
  }, [audioFiles, fileName, onPlay, onPlaybackUpdate, playerId]);

  useEffect(() => {
    if (currentAudio) {
      audioRef.current.src = currentAudio;
      if (isActivePlayer) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [currentAudio, isActivePlayer]);

  const handleAudioEnd = useCallback(() => {
    audioRef.current.currentTime = 0;
    onPlaybackUpdate(playerId, false, 0);
    onPlay(null);
  }, [onPlaybackUpdate, playerId, onPlay]);

  useEffect(() => {
    const audioElement = audioRef.current;
    
    const handleTimeUpdate = () => {
      onPlaybackUpdate(playerId, isActivePlayer, audioElement.currentTime);
    };

    audioElement.addEventListener('timeupdate', handleTimeUpdate);
    audioElement.addEventListener('ended', handleAudioEnd);

    return () => {
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
      audioElement.removeEventListener('ended', handleAudioEnd);
    };
  }, [isActivePlayer, handleAudioEnd, onPlaybackUpdate, playerId]);

  const togglePlayStop = () => {
    if (isActivePlayer) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      onPlaybackUpdate(playerId, false, 0);
      onPlay(null);
    } else {
      if (fileName && audioFiles[fileName]) {
        playAudio();
      }
    }
  };

  return (
    <div className="audio-player">
      <audio ref={audioRef} />
      <button onClick={togglePlayStop} className="play-stop-button">
        {isActivePlayer ? <StopButton /> : <PlayButton />}
      </button>
    </div>
  );
};

export default AudioPlayer;
