import React from 'react';
import ApplicationForm from '../../components/ApplicationForm';

const DevOpsEngineer = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-4xl font-bold mb-6">DevOps Engineer</h1>
    <h3 className="text-2xl mb-4">Location: Altamonte Springs, FL (On-Site)</h3>
    <h3 className="text-2xl mb-4">Salary: Competitive, based on experience</h3>
    <h3 className="text-2xl mb-4">Positions Available: 1</h3>

    <h2 className="text-3xl font-bold mt-8 mb-4">Job Overview</h2>
    <p className="text-lg mb-6">
      We are seeking a skilled DevOps Engineer to set up and maintain our CI/CD pipelines, manage cloud infrastructure, and ensure that our systems
      are scalable, secure, and highly available. This role includes configuring multiple servers for redundancy to minimize downtime and ensure
      reliability. One experienced engineer can effectively manage these responsibilities, supporting smooth deployments and reliable system performance.
    </p>

    <h2 className="text-3xl font-bold mt-8 mb-4">Key Responsibilities</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Set up, manage, and maintain CI/CD pipelines to automate deployment processes.</li>
      <li>Configure and manage web servers with redundancy to ensure minimal downtime and high availability.</li>
      <li>Manage cloud infrastructure to ensure scalability, reliability, and security.</li>
      <li>Implement load balancing and failover strategies to minimize service disruptions.</li>
      <li>Monitor system performance and troubleshoot issues to maintain high availability.</li>
      <li>Implement and manage security best practices across cloud and deployment pipelines.</li>
      <li>Collaborate with development and QA teams to ensure smooth integration of new features and releases.</li>
      <li>Optimize systems for performance and cost-efficiency.</li>
      <li>Stay current with industry best practices and emerging technologies to continuously improve the infrastructure.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Qualifications</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Proven experience as a DevOps Engineer, with expertise in setting up and managing CI/CD pipelines.</li>
      <li>Strong knowledge of cloud infrastructure management (e.g., AWS, Google Cloud, Azure).</li>
      <li>Experience with automation tools and frameworks (e.g., Jenkins, GitLab CI, Terraform, Ansible).</li>
      <li>Proficiency in setting up redundant web servers and configuring load balancing.</li>
      <li>Strong understanding of system security, scalability, and performance optimization.</li>
      <li>Excellent problem-solving skills and the ability to work independently.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Preferred Skills</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Experience with monitoring and logging tools (e.g., Prometheus, Grafana, ELK Stack).</li>
      <li>Familiarity with database management and optimization in cloud environments.</li>
      <li>Knowledge of scripting languages (e.g., Python, Bash) for automation.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Additional Requirements</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Work Authorization: Candidates must be legally authorized to work in the United States.</li>
      <li>Non-Compete and Confidentiality Agreement: Candidates must agree to sign a non-compete and confidentiality agreement upon hiring.</li>
      <li>On-Site Position: Must work from our Altamonte Springs, FL office.</li>
      <li>Availability: Must be available to work weekends or holidays as needed to meet project demands.</li>
      <li>Smoke-Free Environment: Candidates must maintain a smoke-free status while employed.</li>
      <li>Drug and Alcohol-Free Policy: Pre-employment screening required, and employees must agree to maintain a drug and alcohol-free status while employed.</li>
      <li>Background Check: A background check will be conducted during the hiring process. Criminal records older than 7 years may be reviewed on a case-by-case basis.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">What We Offer</h2>
    <ul className="list-disc list-outside text-lg pl-[4rem]">
      <li>Competitive salary and benefits package.</li>
      <li>Opportunity to work with cutting-edge technology in a growing company.</li>
      <li>A collaborative and innovative work environment.</li>
      <li>Potential for profit-sharing and future ownership percentage opportunities.</li>
    </ul>

    <ApplicationForm careerTitle="DevOps Engineer" />
  </div>
);

export default DevOpsEngineer;
