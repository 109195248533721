import React, { useState, useRef, useEffect, useCallback } from 'react';
import Games from '../components/GameProducts';
import AudioPlayer from '../components/AudioPlayer';
import NeumorphicCard from '../components/NeumorphicCard';
import PageBanner from '../components/PageBanner';

const TruncatedBio = ({ bio }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const bioRef = useRef(null);

  useEffect(() => {
    if (bioRef.current) {
      setIsTruncated(bioRef.current.scrollHeight > bioRef.current.clientHeight);
    }
  }, [bio]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="text-left">
      <div
        ref={bioRef}
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isExpanded ? 'max-h-full' : 'max-h-[7.5em]'
        } ${
          !isExpanded && isTruncated
            ? 'bg-gradient-to-b from-black to-transparent bg-clip-text text-transparent'
            : ''
        }`}
        style={{ WebkitLineClamp: isExpanded ? 'unset' : '5', WebkitBoxOrient: 'vertical', display: '-webkit-box' }}
        dangerouslySetInnerHTML={{ __html: bio }}
      />
      {isTruncated && (
        <button
          onClick={toggleExpand}
          className="text-blue-600 hover:text-blue-800 mt-2 focus:outline-none"
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  );
};

const Game = ({ isPreview = false }) => {
  const [activePlayerId, setActivePlayerId] = useState(null);

  const handlePlaybackUpdate = useCallback((playerId, isPlaying, currentTime) => {
    console.log(`Playback update for ${playerId}: isPlaying=${isPlaying}, currentTime=${currentTime}`);
  }, []);

  const handlePlay = useCallback((playerId) => {
    setActivePlayerId(playerId);
    console.log(`Playing audio for ${playerId}`);
  }, []);

  const previewContent = (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-5xl mb-4">Our Products</h1>
      <p className="text-xl text-center max-w-2xl">
        Meet the brilliant minds behind BitSoft's innovative gaming solutions.
      </p>
    </div>
  );

  const fullContent = (
    <div className="bg-white">
      <PageBanner
        title="Our Products"
        description="Experience innovative solutions that are transforming the future of online casino gaming."
      />

      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-8 text-center">Our Online Casino Software Solutions</h2>
        <NeumorphicCard className="mb-8">
          <p className="text-lg text-left mb-4">
            At BitSoft, we specialize in creating innovative software solutions for the online casino industry, with a strong commitment to fairness 
            and transparency. Our technology is designed to support non-house games, enabling operators to provide experiences where outcomes are 
            determined by player interactions rather than the house. This player-driven approach empowers operators to build fair and engaging 
            environments that foster community-driven outcomes.
          </p>
          <p className="text-lg text-left mb-4">
            Each game is built using advanced cryptographic methods and includes a progressive jackpot system, ensuring that winnings are distributed 
            among players transparently. Even in traditionally structured games, our software ensures that all winnings come from a shared pool, 
            further enhancing player trust.          
          </p>
          <p className="text-lg text-left mb-4">
            By offering non-house games, we enable operators to deliver a gaming experience that is not only fun and exciting but also secure and fair, 
            with outcomes shaped by collective participation.
          </p>
        </NeumorphicCard>
        
        <div>
          <h2 className="text-3xl font-bold text-center">Provably Fair Casino Software Solutions</h2>
          <NeumorphicCard>
            <div class="text-lg mb-6">
              <p class="mb-4">
                We are dedicated to delivering fairness and transparency in every game, ensuring that operators can provide their players with outcomes 
                they can trust. We achieve this through advanced cryptographic methods, utilizing industry-leading algorithms to guarantee that results 
                are both unpredictable and tamper-proof.
              </p>
              <p class="mb-4">
                <strong>How Our Provably Fair System Works</strong>
              </p>
              <p class="mb-4">
                Each game integrates a tailored approach to randomness, but the core principle remains the same: fairness through secure cryptographic techniques.
              </p>
              <ul class="list-disc pl-12 mb-4">
                <li class="mb-2">
                  Fairness Across All Games: Whether it's Trimorra, Keno, Bingo, or Lottery, each game utilizes a unique cryptographic process to 
                  ensure that all outcomes are unbiased and independently verifiable. Every game round operates with its own secure randomness, 
                  preventing any interference or manipulation of results.</li>
                <li class="mb-2">
                  Verifiable Outcomes: We go beyond traditional fairness models by offering provably fair gaming. Operators and players alike can access 
                  cryptographic proofs that verify the integrity of each game, ensuring transparency and trust in the results. This guarantees that 
                  outcomes are fair and adhere to the highest industry standards.
                </li>
              </ul>
              <p class="mb-4">
                <strong>Trusted by Operators and Players</strong>
              </p>
              <p>
                Our commitment to provably fair gaming gives players confidence that every game is conducted with full transparency. For operators, this 
                translates into a platform built on trust, ensuring that all gaming experiences meet the highest levels of fairness and security. By using 
                advanced cryptographic methods, BitSoft’s gaming solutions deliver unparalleled reliability without compromising performance.
              </p>
            </div>
          </NeumorphicCard>
        </div>

        <div>
          <h2 className="text-3xl font-bold text-center">Our Progressive Betting System</h2>
          <NeumorphicCard>
            <p className="text-lg text-left mb-8">
              Our progressive jackpot model brings fairness and excitement to all games, including Trimorra, Keno, and Lottery. Each game features a dynamic 
              jackpot system where prize pools grow as more players participate, ensuring everyone has a chance to win. This approach builds anticipation as 
              jackpots increase over time, offering thrilling opportunities for big payouts.
            </p>
            <p className="text-lg text-left mb-8">
              In both Trimorra and Keno, the jackpot system adjusts based on the player’s wager, allowing for flexible participation with substantial rewards. 
              When jackpots are won, they are drawn from shared pools, guaranteeing transparency and fairness. Whether engaging in smaller or larger bets, 
              players experience a fair, engaging, and transparent gaming environment.
            </p> 
          </NeumorphicCard>
        </div>
        
        <h2 className="text-3xl font-bold mb-8 text-center">Explore Our Game Offerings</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {Games.map(game => (
            <NeumorphicCard key={game.id} className="relative">
              {game.audioFile && (
                <div className="absolute top-12 right-12 z-10">
                  <AudioPlayer 
                    fileName={game.audioFile}
                    onPlaybackUpdate={handlePlaybackUpdate} 
                    playerId={`player-${game.id}`}
                    isActivePlayer={activePlayerId === `player-${game.id}`}
                    onPlay={handlePlay}
                  />
                </div>
              )}
              <div className="relative z-0">
                <div className="h-32 flex items-center justify-center mb-4">
                  <img 
                    src={game.image || 'path-to-placeholder-image.png'} 
                    alt={game.name} 
                    className="max-w-full max-h-full object-contain" 
                  />
                </div>
                <h2 className="text-2xl font-bold text-center mb-2">{game.name}</h2>
                <p className="text-center text-gray-600 mb-2">{game.position}</p>
                <TruncatedBio bio={game.bio} />
              </div>
            </NeumorphicCard>
          ))}
        </div>
      </div>
    </div>
  );

  return isPreview ? previewContent : fullContent;
};

export default Game;
