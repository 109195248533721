import React from 'react';
import ApplicationForm from '../../components/ApplicationForm';

const ITSupport = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-4xl font-bold mb-6">IT Support</h1>
    <h3 className="text-2xl mb-4">Location: Altamonte Springs, FL (On-Site)</h3>
    <h3 className="text-2xl mb-4">Salary: Competitive, based on experience</h3>
    <h3 className="text-2xl mb-4">Positions Available: 1</h3>

    <h2 className="text-3xl font-bold mt-8 mb-4">Job Overview</h2>
    <p className="text-lg mb-6">
      We are seeking a dedicated IT Support specialist to manage the technical support needs of our team, with a primary focus on supporting the CTO.
      As the sole IT Support member, you will be responsible for troubleshooting hardware and software issues, maintaining the development environment,
      and ensuring that all systems are optimized for efficiency. This role requires proficiency in Debian, Windows, and possibly Mac operating systems,
      particularly to support graphic design tools.
    </p>

    <h2 className="text-3xl font-bold mt-8 mb-4">Key Responsibilities</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Provide direct IT support to the CTO, assisting with technical challenges and system maintenance.</li>
      <li>Troubleshoot hardware, software, and network-related issues across Debian, Windows, and Mac environments.</li>
      <li>Maintain and optimize the development environment, ensuring tools and systems are up to date.</li>
      <li>Set up, configure, and manage computers, peripherals, and other IT equipment.</li>
      <li>Assist with onboarding new team members by setting up IT systems and accounts.</li>
      <li>Manage and maintain software licenses and IT inventory.</li>
      <li>Monitor system performance and ensure the development environment is secure and stable.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Qualifications</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Proven experience as an IT Support specialist or similar role.</li>
      <li>Strong knowledge of Debian, Windows, and Mac operating systems.</li>
      <li>Familiarity with development tools, software, and IDEs (e.g., Visual Studio Code).</li>
      <li>Excellent problem-solving skills and the ability to work independently.</li>
      <li>Strong communication skills and the ability to assist team members with varying levels of technical knowledge.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Additional Requirements</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Work Authorization: Candidates must be legally authorized to work in the United States.</li>
      <li>Non-Compete and Confidentiality Agreement: Candidates must agree to sign a non-compete and confidentiality agreement upon hiring.</li>
      <li>On-Site Position: This is an on-site position, and candidates must be able to work from our Altamonte Springs, FL office.</li>
      <li>Availability: Candidates must be available to work weekends or holidays as needed to meet project demands.</li>
      <li>Smoke-Free Environment: We maintain a smoke-free workplace due to health concerns and to promote productivity.</li>
      <li>Drug and Alcohol-Free Policy: Pre-employment screening required, and employees must agree to maintain a drug and alcohol-free status while employed.</li>
      <li>Background Check: A background check will be conducted during the hiring process. Criminal records older than 7 years may be reviewed on a case-by-case basis.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">What We Offer</h2>
    <ul className="list-disc list-outside text-lg pl-[4rem]">
      <li>Competitive salary and benefits package.</li>
      <li>Opportunity to work with cutting-edge technology in a growing company.</li>
      <li>A collaborative and innovative work environment.</li>
      <li>The chance to make a significant impact on the future of our company and products.</li>
      <li>Potential for profit-sharing and future ownership percentage opportunities.</li>
    </ul>

    <ApplicationForm careerTitle="IT Support" />
  </div>
);

export default ITSupport;
