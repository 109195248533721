import React from 'react';
import ApplicationForm from '../../components/ApplicationForm';

const ProjectManager = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-4xl font-bold mb-6">Project Manager</h1>
    <h3 className="text-2xl mb-4">Location: Altamonte Springs, FL (On-Site)</h3>
    <h3 className="text-2xl mb-4">Salary: Competitive, based on experience</h3>
    <h3 className="text-2xl mb-4">Positions Available: 2</h3>

    <h2 className="text-3xl font-bold mt-8 mb-4">Job Overview</h2>
    <p className="text-lg mb-6">
      We are seeking two experienced Project Managers to join our team, one to oversee front-end development and one for back-end development.
      As a key leader in the company, you will work closely with the CEO to align on goals, timelines, and deliverables.
      In addition to managing the development process, you will be instrumental in building your own team, participating in the hiring and screening process for developers.
    </p>

    <h2 className="text-3xl font-bold mt-8 mb-4">Key Responsibilities</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Collaborate with the CEO to define project goals, deadlines, and deliverables.</li>
      <li>Lead and manage the development process for either front-end or back-end teams.</li>
      <li>Participate in the interview and screening process for hiring developers.</li>
      <li>Track project timelines and ensure the team stays on schedule.</li>
      <li>Coordinate between developers, designers, and other departments to ensure smooth workflow.</li>
      <li>Provide regular updates on progress and address any roadblocks or challenges.</li>
      <li>Facilitate communication and collaboration among team members.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Qualifications</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Proven experience as a Project Manager, preferably in software development or technology projects.</li>
      <li>Strong understanding of front-end or back-end development processes.</li>
      <li>Excellent leadership, communication, and organizational skills.</li>
      <li>Experience managing project timelines and delivering projects on schedule.</li>
      <li>Familiarity with project management tools (e.g., Jira, Trello, Asana).</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Additional Requirements</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Work Authorization: Candidates must be legally authorized to work in the United States.</li>
      <li>Non-Compete and Confidentiality Agreement required upon hiring.</li>
      <li>On-Site Position: Must work from our Altamonte Springs, FL office.</li>
      <li>Availability for weekends or holidays if project demands.</li>
      <li>Drug and Alcohol-Free Policy: Pre-employment screening required.</li>
      <li>Background Check required, reviewed on a case-by-case basis.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">What We Offer</h2>
    <ul className="list-disc list-outside text-lg pl-[4rem]">
      <li>Competitive salary and benefits package.</li>
      <li>Opportunity to work with cutting-edge technology in a growing company.</li>
      <li>A collaborative and innovative work environment.</li>
      <li>Potential for profit-sharing and future ownership percentage opportunities.</li>
    </ul>

    <ApplicationForm careerTitle="Project Manager" />
  </div>
);

export default ProjectManager;
