import React from 'react';
import ApplicationForm from '../../components/ApplicationForm';
const FrontEndDeveloper = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-4xl font-bold mb-6">Front-End Developer</h1>
    <h3 className="text-2xl mb-4">Location: Altamonte Springs, FL (On-Site)</h3>
    <h3 className="text-2xl mb-4">Salary: Competitive, based on experience</h3>
    <h3 className="text-2xl mb-4">Positions Available: 4</h3>

    <h2 className="text-3xl font-bold mt-8 mb-4">Job Overview</h2>
    <p className="text-lg mb-6">
      We are seeking skilled Front-End Developers with strong SQL capabilities to join our team. In this role, you will develop seamless, responsive 
      user interfaces using SolidJS and Tailwind CSS, with a focus on data-aware applications. You will work with an SQLite3 in-memory database, 
      integrating front-end components with data models and designing objects that interact efficiently with backend systems.
    </p>

    <h2 className="text-3xl font-bold mt-8 mb-4">Key Responsibilities</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Develop and maintain responsive user interfaces using SolidJS and Tailwind CSS.</li>
      <li>Collaborate with backend developers and designers to ensure seamless integration of front-end components with backend systems.</li>
      <li>Design objects that efficiently interact with data from an SQLite3 in-memory database.</li>
      <li>Handle event messaging triggered by CRUD actions in the front-end system.</li>
      <li>Optimize front-end performance to ensure scalability and responsiveness.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Qualifications</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Proven experience as a Front-End Developer with a strong portfolio of responsive web applications.</li>
      <li>Proficiency in SolidJS, Tailwind CSS, and JavaScript frameworks.</li>
      <li>Extensive knowledge of SQL and query optimization.</li>
      <li>Experience working with SQLite3 or similar in-memory databases.</li>
      <li>Ability to handle event messaging triggered by CRUD actions.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Additional Requirements</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Work Authorization: Candidates must be legally authorized to work in the United States.</li>
      <li>Non-Compete and Confidentiality Agreement: Candidates must agree to sign a non-compete and confidentiality agreement upon hiring.</li>
      <li>On-Site Position in Altamonte Springs, FL.</li>
      <li>Availability for weekends or holidays as needed to meet project demands.</li>
      <li>Smoke-Free Environment: Candidates must maintain a smoke-free status while employed.</li>
      <li>Drug and Alcohol-Free Policy: Pre-employment screening required.</li>
      <li>Background Check required, reviewed on a case-by-case basis.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">What We Offer</h2>
    <ul className="list-disc list-outside text-lg pl-[4rem]">
      <li>Competitive salary and benefits package.</li>
      <li>Opportunity to work with cutting-edge technology in a growing company.</li>
      <li>A collaborative and innovative work environment.</li>
      <li>Potential for profit-sharing and future ownership percentage opportunities.</li>
    </ul>

    <ApplicationForm careerTitle="Front-End Developer" />
  </div>
);

export default FrontEndDeveloper;
