import React from 'react';
import ApplicationForm from '../../components/ApplicationForm';
const MarketingSpecialist = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-4xl font-bold mb-6">Marketing Specialist</h1>
    <h3 className="text-2xl mb-4">Location: Altamonte Springs, FL (On-Site)</h3>
    <h3 className="text-2xl mb-4">Salary: Competitive, based on experience</h3>
    <h3 className="text-2xl mb-4">Positions Available: 2</h3>

    <h2 className="text-3xl font-bold mt-8 mb-4">Job Overview</h2>
    <p className="text-lg mb-6">
      We are seeking two talented Marketing Specialists to lead and execute our marketing efforts, with one specialist focusing on strategic planning and
      the other on execution. In this role, you will be responsible for developing and managing a comprehensive marketing strategy targeting diverse
      international markets through SEO, content creation, social media management, and paid advertising, ensuring that the campaigns are tailored to
      the unique characteristics of each region.
    </p>

    <h2 className="text-3xl font-bold mt-8 mb-4">Key Responsibilities</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Develop and execute marketing strategies for product launches and ongoing campaigns targeting multiple international markets.</li>
      <li>Oversee SEO initiatives to improve website rankings and organic traffic.</li>
      <li>Manage social media platforms, creating content that resonates with users in various regions and promotes the client’s brand.</li>
      <li>Develop and implement paid advertising strategies across platforms like Google Ads and social media.</li>
      <li>Coordinate content creation, including blogs, newsletters, and promotional materials tailored for regional audiences.</li>
      <li>Analyze campaign performance and optimize strategies based on region-specific data and insights.</li>
      <li>Collaborate with the team to brainstorm, refine, and execute creative marketing campaigns.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Qualifications</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Proven experience as a Marketing Specialist with a strong portfolio of successful international campaigns.</li>
      <li>Experience with SEO, Google Ads, social media marketing, and content creation for diverse markets.</li>
      <li>Strong analytical skills and ability to use data to inform decisions across different regions.</li>
      <li>Excellent communication and project management skills.</li>
      <li>Ability to work both independently and as part of a team in a fast-paced environment.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Preferred Skills</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Familiarity with marketing tools such as Google Analytics, SEMrush, or HubSpot.</li>
      <li>Experience launching marketing campaigns for digital or consumer-focused products in international markets.</li>
      <li>Knowledge of paid advertising strategies and campaign optimization techniques tailored for specific regions.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Additional Requirements</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Work Authorization: Candidates must be legally authorized to work in the United States.</li>
      <li>Non-Compete and Confidentiality Agreement: Candidates must agree to sign a non-compete and confidentiality agreement upon hiring.</li>
      <li>On-Site Position: This is an on-site position, and candidates must be able to work from our Orlando, FL office.</li>
      <li>Availability: Candidates must be available to work weekends or holidays as needed to meet project demands.</li>
      <li>Smoke-Free Environment: Candidates must maintain a smoke-free status while employed.</li>
      <li>Drug and Alcohol-Free Policy: Candidates must pass a pre-employment drug screening and agree to maintain a drug and alcohol-free status while employed.</li>
      <li>Background Check: A background check will be conducted during the hiring process. Criminal records older than 7 years may be reviewed on a case-by-case basis.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">What We Offer</h2>
    <ul className="list-disc list-outside text-lg pl-[4rem]">
      <li>Competitive salary and benefits package.</li>
      <li>Opportunity to work with cutting-edge technology in a growing company.</li>
      <li>A collaborative and innovative work environment.</li>
      <li>The chance to make a significant impact on the future of our company and products.</li>
      <li>Potential for profit-sharing and future ownership percentage opportunities.</li>
    </ul>

    <ApplicationForm careerTitle="Marketing Specialist" />
  </div>
);

export default MarketingSpecialist;
