import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import AudioPlayer from '../components/AudioPlayer';
import NeumorphicCard from '../components/NeumorphicCard';
import PageBanner from '../components/PageBanner';

const Investment = ({ isPreview = false }) => {
  const [activePlayerId, setActivePlayerId] = useState(null);

  const handlePlaybackUpdate = useCallback((playerId, isPlaying, currentTime) => {
    // This function is still used by AudioPlayer, but we're not updating any state here
    // If needed in the future, we can reintroduce the audioStatuses state
    console.log(`Player ${playerId} status: ${isPlaying ? 'playing' : 'paused'} at ${currentTime}`);
  }, []);

  const handlePlay = useCallback((playerId) => {
    setActivePlayerId(playerId);
  }, []);

  const previewContent = (
    <div className="text-white max-w-4xl mx-auto">
      {/* Hero Section */}
      <div className="text-center py-6 px-4">
        <h1 className="text-4xl font-bold mb-2">Investment Opportunities</h1>
        <p className="text-lg mb-2">Join us in shaping the future of online gaming with BitSoft.</p>
      </div>

      {/* Key Highlights Section */}
      <div className="px-4 py-8">
        <div className="grid grid-cols-2 gap-4">
          {[
            { title: "Innovative Technology", description: "Unmatched scalability and security." },
            { title: "Fair Gambling", description: "100% provably fair games." },
            { title: "Gaming Suite", description: "Diverse, fair, and trustworthy games." },
            { title: "Ethical Solutions", description: "Transparent betting systems." },
          ].map((item, index) => (
            <div key={index} className="text-white p-4 rounded-lg">
              <h3 className="text-lg font-semibold mb-1">{item.title}</h3>
              <p className="text-sm">{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="text-center mt-6 mb-8">
        <Link 
          to="/investment" 
          className="bg-white text-black px-8 py-3 rounded-full text-lg font-semibold hover:bg-opacity-90 transition duration-300 inline-block"
        >
          Learn More
        </Link>
      </div>
    </div>
  );

  const fullContent = (
    <div className="bg-white">
      <PageBanner
        title="Invest in the Future of Online Gaming"
        description="Join BitSoft in transforming the online casino industry with innovative, secure, and fair software solutions."
        ctaText="Explore Investment Opportunities"
        ctaLink="https://wefunder.com/bitsoft"
      />

      {/* Main Content */}
      <div className="container mx-auto px-4 py-16">
        {/* Why Invest Section */}
        <div className="relative">
          <div className="flex flex-row items-center justify-center mb-8">
            <h2 className="text-4xl mr-4 font-bold text-black">Why Invest in BitSoft?</h2>
            <AudioPlayer 
              fileName="Investment-WhyInvest.mp3" 
              onPlaybackUpdate={handlePlaybackUpdate} 
              playerId="player1"
              isActivePlayer={activePlayerId === "player1"}
              onPlay={handlePlay}
            />
          </div>
          <NeumorphicCard id="why-invest" className="mb-16">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                { title: "Experienced Leadership", 
                  description: `
                    Our leadership team brings over 100 years of combined experience in developing secure, scalable, and innovative 
                    casino gaming software solutions. While BitSoft is a new venture, our team’s extensive background in online casino 
                    platforms, compliance, and system architecture ensures that we are well-equipped to deliver cutting-edge solutions 
                    tailored to the demands of the casino gaming industry.
                  ` 
                },
                { title: "Diverse Gaming Portfolio", 
                  description: `
                    Our platform offers a wide variety of non-house games, including Bingo, Lottery, Keno, Poker, and the unique 
                    Trimorra, all designed to prioritize fairness and transparency. With no house advantage, winnings are distributed 
                    among players, creating a fair, player-focused experience. Both Poker and Lottery are crafted to drive significant 
                    engagement and revenue.
                  ` 
                },
                { title: "Fair Gambling", 
                description: `
                  By utilizing advanced cryptographic hashing technologies, our platform ensures that every game is 100% provably fair. 
                  Operators and players can independently verify the integrity of each outcome, setting a new industry standard for 
                  transparency. With fairness embedded in every aspect of our games, we provide a secure and trusted environment, 
                  fostering confidence in both operators and players while promoting responsible gaming practices.
                ` 
                },
                { title: "Innovative Technology", 
                  description: `
                    Our platform is engineered for exceptional scalability and security, supporting millions of concurrent connections 
                    with seamless performance. Utilizing advanced cloud infrastructure and encryption protocols, we ensure a stable, 
                    secure environment specifically tailored to meet the high-demand needs of online casino operators.                  
                  `
                },
                { title: "Advanced Accounting", 
                  description: `
                    Our platform’s accounting system keeps all deposited funds in their original currency, including cryptocurrency. 
                    Wagers are seamlessly fulfilled using real-time exchange rates, and winnings are returned in the same currency 
                    used for wagering. This approach ensures flexibility and accuracy in managing transactions, making the process 
                    straightforward and efficient for both operators and players.                  
                  `
                },
                { title: "Regulatory Compliance", 
                  description: `
                    Our platform is built to adhere to the highest international gaming regulations, ensuring operators meet strict 
                    compliance requirements. Designed with a focus on legal standards, our software provides operators with the assurance 
                    that they are using a system crafted for regulatory alignment. This commitment to compliance fosters a secure and 
                    responsible gaming environment, helping operators navigate the complexities of the casino gaming industry.
                  ` 
                },
              ].map((item, index) => (
                <NeumorphicCard key={index} className="p-6">
                  <h3 className="text-xl font-semibold mb-3">{item.title}</h3>
                  <p>{item.description}</p>
                </NeumorphicCard>
              ))}
            </div>
          </NeumorphicCard>
        </div>

        {/* Vision Section */}
        <div className="flex flex-row items-center justify-center mb-8">
          <h2 className="text-3xl mr-4 font-bold text-center">Our Vision</h2>
          <AudioPlayer 
            fileName="Investment-OurVision.mp3" 
            onPlaybackUpdate={handlePlaybackUpdate} 
            playerId="player2"
            isActivePlayer={activePlayerId === "player2"}
            onPlay={handlePlay}
          />
        </div>
        <NeumorphicCard id="vision" className="mb-16">
          <p className="text-lg mb-4">
            At BitSoft, we aim to revolutionize the online casino industry by addressing longstanding challenges and creating a more ethical, transparent, 
            and player-centered environment. We are committed to reshaping the industry to prioritize fairness, integrity, and responsible gaming practices.
          </p>
          <p className="text-lg mb-4">
            Too often, online casinos prioritize profit over players by using exploitative tactics that have historically caused harm. At BitSoft, our goal 
            is to deliver the excitement of gaming through solutions focused on trust and transparency. We aim to provide players with an enjoyable and fair 
            experience while enabling operators to maximize revenue without compromising ethical standards.
          </p>
        </NeumorphicCard>

        {/* Fairness and Transparency Section */}
        <h2 className="text-3xl font-bold mb-8 text-center">Fairness and Transparency at Our Core</h2>
        <NeumorphicCard id="fairness-transparency" className="mb-16">
        <div>
          <h2 className="text-2xl font-bold text-center">Provably Fair Casino Gaming</h2>
          <NeumorphicCard>
            <div class="text-lg mb-6">
              <p class="mb-4">
                We are dedicated to delivering fairness and transparency in every game, ensuring that players can trust the outcomes. We achieve this through advanced cryptographic methods, using the industry-leading SHAKE256 algorithm to guarantee that results are unpredictable and cannot be manipulated.
              </p>
              <p class="mb-4">
                <strong>How Our Provably Fair System Works</strong>
              </p>
              <p class="mb-4">
                Each game follows a tailored approach to randomness, but the core principle remains the same: fairness through secure cryptographic techniques.
              </p>
              <ul class="list-disc pl-12 mb-4">
                <li class="mb-2">Fairness Across All Games: Whether it's Trimorra, Keno, Bingo, or Lottery, each game utilizes a unique cryptographic process to ensure that all outcomes are unbiased and independently verifiable. Every game round operates with its own secure randomness, preventing any interference or manipulation of results.</li>
                <li class="mb-2">Verifiable Outcomes: We go beyond traditional fairness models by offering provably fair gaming. Players and operators can access cryptographic proofs that verify the integrity of each game, allowing for transparency in the results. This ensures that all outcomes are fair and consistent with the highest standards in the industry.</li>
              </ul>
              <p class="mb-4">
                <strong>Trusted by Players and Operators</strong>
              </p>
              <p>
                Our commitment to provably fair gaming gives players confidence that every game is conducted with full transparency. For operators, this translates into a platform built on trust, ensuring that all gaming experiences meet the highest levels of fairness and security. By using advanced cryptographic methods, BitSoft’s gaming solutions deliver unparalleled reliability without compromising performance.
              </p>
            </div>
          </NeumorphicCard>
        </div>

        <div>
          <h2 className="text-2xl font-bold text-center">Our Progressive Betting System</h2>
          <NeumorphicCard>
            <p className="text-lg text-left mb-8">
              Ensuring that all of our games, including Trimorra, Keno, and Lottery, operate on a progressive jackpot model designed to offer fairness and excitement to players. Each game features a dynamic jackpot system, where winnings are drawn from a prize pool that grows as more players participate. This approach ensures that every player has a chance to win, with jackpots building over time to create thrilling opportunities for big payouts.
            </p>
            <p className="text-lg text-left mb-8">
              In Trimorra and Keno, the jackpot system adjusts based on the player’s wager, allowing for flexible participation while still offering substantial rewards. When jackpots are won, they are drawn from aggregate pools, providing transparency and fairness in every game. Whether participating in smaller or larger bets, players benefit from a fair, engaging, and transparent gaming experience.
            </p> 
          </NeumorphicCard>
        </div>
        
        </NeumorphicCard>

        {/* Tackling Exploitative Practices Section */}
        <h2 className="text-3xl font-bold mb-8 text-center">Tackling Exploitative Industry Practices</h2>
        <NeumorphicCard className="mb-16">
          <div className="grid md:grid-cols-2 gap-8 mb-8">
            {/* Unethical Tactics We're Eliminating Section */}
            <div className="flex flex-col">
              <h3 className="text-xl font-semibold mb-3">Unethical Tactics We're Eliminating</h3>
              <div className="grid grid-cols-1 gap-4">
                {[
                  "Manipulative bonus structures that trap players in wagering cycles",
                  "Deceptive odds presentations that obscure true winning probabilities",
                  "Aggressive marketing targeting vulnerable individuals",
                  "Slow withdrawal processes designed to encourage further gambling",
                  "Ambiguous odds and mechanics that confuse players"
                ].map((item, index) => (
                  <div key={index} className="bg-red-200 p-4 rounded-lg shadow-[inset_5px_5px_10px_rgba(0,0,0,0.1),_inset_-5px_-5px_10px_rgba(255,255,255,0.8)]">
                    <p className="text-lg">{item}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Our Ethical Solutions Section */}
            <div className="flex flex-col">
              <h3 className="text-xl font-semibold mb-3">Our Ethical Solutions</h3>
              <div className="grid grid-cols-1 gap-4">
                {[
                  "Transparent, easy-to-understand pari-mutuel betting systems",
                  "Clear and understandable display of odds and mathematical calculations",
                  "Strict self-exclusion and responsible gaming tools",
                  "Fast, hassle-free withdrawal processes",
                  "Educational resources on probability and responsible gambling"
                ].map((item, index) => (
                  <div key={index} className="bg-green-200 p-4 rounded-lg shadow-[5px_5px_10px_rgba(0,0,0,0.1),_-5px_-5px_10px_rgba(255,255,255,0.8)]">
                    <p className="text-lg">{item}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <p className="text-lg">
            These measures not only protect players but also create a more sustainable and ethical gaming environment.
          </p>
        </NeumorphicCard>

        {/* Future of Online Gambling Section */}
        <h2 className="text-3xl font-bold mb-8 text-center">The Future of Online Gambling</h2>
        <NeumorphicCard className="mb-16">
          <p className="text-lg">
            We envision a future where online gambling goes beyond entertainment to become a model of <strong>technological innovation</strong>, 
            <strong>ethical standards</strong>, and <strong>user empowerment</strong>. By equipping operators with cutting-edge tools, we aim to 
            set new industry standards where fairness, transparency, and player well-being are non-negotiable principles. Our commitment to these 
            ideals will help reshape global perceptions of online gambling, reimagining online casinos as platforms built in the best interests of 
            players and operators alike.
          </p>
        </NeumorphicCard>

        {/* Key Milestone Section */}
        <h2 className="text-3xl font-bold mb-8 text-center">Key Milestone</h2>
        <NeumorphicCard className="mb-16">
          <h3 className="text-2xl font-semibold mb-4">Secured a 10-Year Licensing Agreement</h3>
          <p className="text-lg mb-4">
            BitSoft has secured a <strong>10-year licensing agreement with a licensed operator in Costa Rica</strong>, demonstrating trust and 
            confidence in our technology and vision. This long-term partnership positions us for rapid global growth and provides a solid 
            foundation for expanding our innovative solutions to operators worldwide.
          </p>
          <p className="text-lg">
            Our commitment to a decade-long collaboration underscores the reliability and scalability of our platform, assuring investors of our 
            dedication to sustained success in the competitive online gambling industry.
          </p>
          <p className="text-center">
            <a 
              href="/docs/Software-License-Agreement.pdf" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="bg-black text-white mt-6 px-10 py-4 rounded-full text-xl font-semibold hover:transition duration-300 inline-block shadow-[5px_5px_10px_rgba(0,0,0,0.3)] hover:shadow-[10px_10px_20px_rgba(0,0,0,0.5)] hover:scale-105 active:shadow-[inset_5px_5px_10px_rgba(0,0,0,0.5)] active:scale-95"
            >
              View Licensings Agreement
            </a>

          </p>
        </NeumorphicCard>

        {/* Legal Compliance */}
        <h2 className="text-3xl font-bold mb-8 text-center">Legal Compliance</h2>
        <NeumorphicCard className="mb-16">
          <p className="text-lg">
            BitSoft is proud to offer a platform that fully aligns with U.S. federal regulations, including the <strong>Wire Act</strong>, which governs online gaming activities. 
            A recent ruling in the <a href="https://www.swlaw.com/publication/federal-court-holds-that-doj-cannot-prosecute-company-for-non-sports-betting-under-wire-act/" 
            target="_blank" style={{ color: 'blue', textDecoration: 'underline' }}>
            IGT vs. DOJ, 2022
            </a> case has reaffirmed that the Wire Act applies exclusively to sports betting. Since our focus is on non-sports games like lotteries, bingo, keno, and poker, 
            BitSoft operates with complete confidence in our compliance with U.S. laws. This legal clarity strengthens the integrity of our platform, providing a solid foundation 
            for growth and success for our partners and investors.
          </p>
        </NeumorphicCard>

        {/* CTA Section */}
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-6">Get Involved</h2>
          <p className="text-xl mb-8">Join us in shaping the future of online gambling. Explore how you can be part of BitSoft's global expansion.</p>
          <a href="https://wefunder.com/bitsoft" target="_blank" rel="noopener noreferrer" className="bg-black text-white px-10 py-4 rounded-full text-xl font-semibold hover:transition duration-300 inline-block shadow-[5px_5px_10px_rgba(0,0,0,0.3)] hover:shadow-[10px_10px_20px_rgba(0,0,0,0.5)] hover:scale-105 active:shadow-[inset_5px_5px_10px_rgba(0,0,0,0.5)] active:scale-95">Invest Now on WeFunder</a>
        </div>

        {/* Disclaimer */}
        <p className="text-sm italic text-black">
          We are currently considering an offering of securities under Regulation Crowdfunding and are gauging potential investor interest. Please note that we are not accepting any money or other form of consideration, and no commitment to purchase securities can be accepted at this time. Any offer to buy securities will only be made through{' '}
          <span className="underline">Wefunder's platform</span>{' '}
          after the filing of the required Form C with the Securities and Exchange Commission. Indicating interest does not involve any obligation or commitment of any kind.
        </p>
      </div>
    </div>
  );
  
  return isPreview ? previewContent : fullContent;
};

export default Investment;