import React from 'react';
import { Link } from 'react-router-dom';
import NeumorphicCard from '../components/NeumorphicCard';
import PageBanner from '../components/PageBanner';

const Careers = ({ isPreview = false }) => {
  const previewContent = (
    <div className="max-w-4xl mx-auto">
      <div className="text-left py-6 px-4">
        <h1 className="text-4xl text-center font-bold mb-2">Careers at BitSoft</h1>
        <p className="text-center text-xl mb-2">
          Help us transform online casino technology.
        </p>
        <br/>
        <p className="text-lg mb-2">
          At BitSoft, we’re shaping the future of online casino software with groundbreaking technology and a commitment to fairness. 
          We're searching for talented individuals ready to push boundaries and contribute to creating secure, innovative solutions 
          that will redefine our industry.
        </p>
      </div>
      <div className="text-center mt-6 mb-8">
        <Link 
          to="/career" 
          className="bg-black text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-opacity-90 transition duration-300 inline-block"
        >
          View Job Openings
        </Link>
      </div>
    </div>
  );

  const fullContent = (
    <div className="bg-white">
      <PageBanner
        title="Career Opportunities at BitSoft"
        description="Join us and drive the future of online casino software with innovation and fairness!"
      />

      {/* Main Content */}
      <div className="container mx-auto px-4 py-16">
        <NeumorphicCard className="mb-16">
          <h2 className="text-3xl font-bold mb-6">Join Our Team</h2>
          <p className="text-lg mb-6">
            At BitSoft, we’re assembling a highly skilled team to lead innovation in the online casino software industry. Our mission is 
            to develop transparent, scalable, and secure solutions that set new standards for both operators and players.
          </p>
        </NeumorphicCard>

        <h2 className="text-3xl font-bold mt-8 mb-4">Why Work With Us?</h2>
        <NeumorphicCard className="mb-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <NeumorphicCard className="p-4">
              <strong>Make an Impact:</strong> As we expand, every role at BitSoft plays a key part in shaping our products and company. Your contributions will directly influence our success and growth.
            </NeumorphicCard>
            <NeumorphicCard className="p-4">
              <strong>Innovate in Online Gaming:</strong> Be part of a team that's transforming the online casino industry with cutting-edge, provably fair software. We're pushing the boundaries of what's possible in online casino technology.
            </NeumorphicCard>
            <NeumorphicCard className="p-4">
              <strong>Collaborative and Inclusive Environment:</strong> We foster an open and respectful culture where every voice is valued, and innovation thrives through collaboration.
            </NeumorphicCard>
            <NeumorphicCard className="p-4">
              <strong>Rewarding Contributions:</strong> We focus on helping you excel in your role and reward that excellence with great profit-sharing opportunities. While we're a lean team, your hard work and dedication will be recognized and rewarded as we grow together.
            </NeumorphicCard>
          </div>
        </NeumorphicCard>

        <h2 className="text-3xl font-bold mt-8 mb-4">Current Career Openings</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            { title: "Erlang Developer (2)", path: "/career/erlang-developer", description: "Build scalable back-end systems that handle millions of simultaneous connections and ensure real-time communication." },
            { title: "Project Manager (2)", path: "/career/project-manager", description: "Oversee the development process for front-end or back-end teams and play a pivotal role in managing timelines, goals, and team coordination." },
            { title: "Back-End Software Developer (2)", path: "/career/backend-developer", description: "Develop and maintain scalable back-end systems, ensuring high performance and secure interactions between the database and the platform." },
            { title: "Front-End Software Developer (4)", path: "/career/frontend-developer", description: "Design and develop game interfaces using SolidJS and Tailwind CSS, integrating with an in-memory SQL database through API calls based on real-time messages from the game server." },
            { title: "QA Engineer (2)", path: "/career/qa-engineer", description: "Conduct manual and automated testing to ensure product quality across platforms before launch." },
            { title: "IT Support", path: "/career/it-support", description: "Provide technical support, troubleshoot issues, and maintain the development environment across multiple operating systems." },
            { title: "Graphic Designer (2)", path: "/career/graphic-designer", description: "Create visual assets for games and marketing campaigns, ensuring consistency across platforms." },
            { title: "DevOps Engineer", path: "/career/devops-engineer", description: "Set up and maintain CI/CD pipelines, manage cloud infrastructure, and ensure high system availability and security." },
            { title: "Marketing Specialist (2)", path: "/career/marketing-specialist", description: "Lead and execute marketing strategies, manage SEO, social media, and paid advertising for international markets." },
            { title: "Chief Financial Officer (CFO)", path: "/career/cfo", description: "Oversee financial operations, ensure compliance with financial regulations, and audit customer records to maintain licensing compliance." },
          ].map((job, index) => (
            <div 
              key={index} 
              onClick={() => { window.location.href = job.path; }}
              className="cursor-pointer"
            >
              <NeumorphicCard className="h-full hover:shadow-lg transition-shadow duration-300">
                <h3 className="text-xl font-semibold mb-3">{job.title}</h3>
                <p className="text-sm">{job.description}</p>
              </NeumorphicCard>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  
  return isPreview ? previewContent : fullContent;
};

export default Careers;