import React from 'react';
import { ReactComponent as BitSoftLogo } from '../assets/BitSoftLogo.svg';
import { ReactComponent as BitSoftLogoWord } from '../assets/BitSoftLogoWord.svg';

const isLightColor = (color) => color === 'black';

const Logo = ({ color = 'black', className = '', width = '160px', height = '50px' }) => {
  const shouldInvert = color && !isLightColor(color);

  // Calculate sizes for individual components
  const iconWidth = `${parseInt(width) * 0.3125}px`; // 50/160 = 0.3125
  const wordWidth = `${parseInt(width) * 0.625}px`;  // 100/160 = 0.625

  return (
    <div className={`logo-container ${className}`} style={{ display: 'flex', alignItems: 'center', width, height }}>
      <BitSoftLogo style={{ width: iconWidth, height: 'auto', marginRight: '10px' }} />
      <BitSoftLogoWord
        style={{
          width: wordWidth,
          height: 'auto',
          filter: shouldInvert ? 'invert(1)' : 'none',
        }}
      />
    </div>
  );
};

export default Logo;
