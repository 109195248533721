import React, { useState } from 'react';

const ApplicationForm = ({ careerTitle }) => {
  const [name, setName] = useState('');
  const [salaryRequirements, setSalaryRequirements] = useState({ formatted: '', raw: '' });
  const [isMaxSalary, setIsMaxSalary] = useState(false);

  const MAX_SALARY = 250000;

  const formatNumber = (num) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleSalaryChange = (e) => {
    let rawValue = e.target.value.replace(/[^0-9]/g, '');
    if (parseInt(rawValue, 10) > MAX_SALARY) {
      rawValue = MAX_SALARY.toString();
      setIsMaxSalary(true);
    } else {
      setIsMaxSalary(false);
    }
    const formattedValue = formatNumber(rawValue);
    setSalaryRequirements({ formatted: formattedValue, raw: rawValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedSalary = salaryRequirements.raw ? `$${formatNumber(salaryRequirements.raw)}/yr` : 'Not specified';
    const subject = encodeURIComponent(`APPLICATION: ${careerTitle} - ${name}`);
    const body = encodeURIComponent(`Desired Salary: ${formattedSalary}`);

    const mailtoLink = `mailto:careers@bitsoft.llc?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="container mx-auto px-4 py-8"> {/* Ensure alignment with the rest of the page */}
      <h2 className="text-3xl font-bold mb-4">How to Apply</h2>
      <p className="text-lg mb-6">
        Please fill out the form below to apply for the <strong>{careerTitle}</strong> position.
      </p>
      <p className="text-lg mb-6">
        Once you submit, an email will be generated. Be sure to attach your <strong>cover letter</strong> (or include it in the email body)
        and <strong>résumé</strong> (preferably in PDF format) when sending.        
      </p>
      <form onSubmit={handleSubmit} className="mt-8">
        <div className="mb-4">
          <label htmlFor="name" className="block text-lg font-medium mb-2">
            Name<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-1/2 px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="salaryRequirements" className="block text-lg font-medium mb-2">
            Desired Salary<span className="text-red-500">*</span>
          </label>
          <div className="relative inline-block">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600">$</span>
            <input
              type="text"
              id="salaryRequirements"
              value={salaryRequirements.formatted}
              onChange={handleSalaryChange}
              required
              maxLength="7"
              className={`w-[16ch] pl-7 pr-12 py-2 border rounded-md ${
                isMaxSalary ? 'border-red-500' : 'border-gray-300'
              }`}
              placeholder="45,000"
            />
            <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600">/yr</span>
          </div>
          {isMaxSalary && (
            <p className="text-red-600 text-sm mt-1">Maximum salary limit reached.</p>
          )}
        </div>

        <p className="text-sm mb-4">*Required Fields</p>

        <button type="submit" className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800">
          Generate Email
        </button>
      </form>
    </div>
  );
};

export default ApplicationForm;
