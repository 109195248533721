import React from 'react';
import ApplicationForm from '../../components/ApplicationForm';

const BackEndDeveloper = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-4xl font-bold mb-6">Back-End Software Developer</h1>
    <h3 className="text-2xl mb-4">Location: Altamonte Springs, FL (On-Site)</h3>
    <h3 className="text-2xl mb-4">Salary: Competitive, based on experience</h3>
    <h3 className="text-2xl mb-4">Positions Available: 2</h3>

    <h2 className="text-3xl font-bold mt-8 mb-4">Job Overview</h2>
    <p className="text-lg mb-6">
      We are seeking experienced Back-End Developers to join our team. With strong technical leadership from both the CEO and CTO, you will play a
      key role in optimizing and maintaining robust, scalable, and secure back-end systems. You will collaborate with our Erlang-based communication
      system to ensure seamless interaction between the client and back-end server. You will be responsible for developing and optimizing the back-end logic that supports this architecture.
    </p>

    <h2 className="text-3xl font-bold mt-8 mb-4">Key Responsibilities</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Design, develop, and maintain scalable and secure back-end systems.</li>
      <li>Optimize back-end processes for performance and efficiency.</li>
      <li>Ensure data integrity and efficient interaction with databases.</li>
      <li>Troubleshoot, debug, and resolve back-end issues to maintain system stability.</li>
      <li>Collaborate with front-end and communication teams to ensure seamless system integration.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Qualifications</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Proven experience as a Back-End Developer with strong knowledge of Java, VoltDB (or other in-memory databases), and database management.</li>
      <li>Strong understanding of server-side logic and high-performance data processing.</li>
      <li>Ability to troubleshoot, debug, and optimize back-end processes.</li>
      <li>Knowledge of version control systems (e.g., Git) and CI/CD pipelines.</li>
      <li>Ability to work effectively in a small, collaborative team environment.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Additional Requirements</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Work Authorization: Candidates must be legally authorized to work in the United States.</li>
      <li>Non-Compete and Confidentiality Agreement required upon hiring.</li>
      <li>On-Site Position in Altamonte Springs, FL.</li>
      <li>Availability for weekends or holidays if project demands.</li>
      <li>Smoke-Free Environment: Candidates must agree to maintain a smoke-free status while employed.</li>
      <li>Drug and Alcohol-Free Policy: Pre-employment screening required.</li>
      <li>Background Check required, reviewed on a case-by-case basis.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">What We Offer</h2>
    <ul className="list-disc list-outside text-lg pl-[4rem]">
      <li>Competitive salary and benefits package.</li>
      <li>Opportunity to work with cutting-edge technology in a growing company.</li>
      <li>A collaborative and innovative work environment.</li>
      <li>Potential for profit-sharing and future ownership percentage opportunities.</li>
    </ul>

    <ApplicationForm careerTitle="Back-End Developer" />
  </div>
);

export default BackEndDeveloper;
