import React from 'react';
import ApplicationForm from '../../components/ApplicationForm';

const ErlangDeveloper = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-4xl font-bold mb-6">Erlang Developer</h1>
    <h3 className="text-2xl mb-4">Location: Altamonte Springs, FL (On-Site)</h3>
    <h3 className="text-2xl mb-4">Salary: Competitive, based on experience</h3>
    <h3 className="text-2xl mb-4">Positions Available: 2</h3>

    <h2 className="text-3xl font-bold mt-8 mb-4">Job Overview</h2>
    <p className="text-lg mb-6">
      We are seeking highly skilled Erlang Developers to join our team in building scalable, high-performance software systems.
      Your primary responsibility will be developing and maintaining systems capable of handling millions of simultaneous connections with real-time database communication.
    </p>

    <h2 className="text-3xl font-bold mt-8 mb-4">Key Responsibilities</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Develop and maintain back-end systems using Erlang, ensuring scalability and reliability.</li>
      <li>Optimize system performance to handle millions of simultaneous connections.</li>
      <li>Design and implement secure and efficient communication between the platform and the database.</li>
      <li>Collaborate closely with front-end developers and other teams to ensure seamless data flow and real-time updates.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Qualifications</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Proven experience in Erlang development, with a focus on scalability and fault tolerance.</li>
      <li>Strong understanding of concurrent programming and distributed systems.</li>
      <li>Experience in database integration and real-time data processing.</li>
      <li>Knowledge of telecom protocols or message-passing systems is a plus.</li>
      <li>Experience in handling high-performance and high-availability systems.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">Additional Requirements</h2>
    <ul className="list-disc list-outside text-lg mb-6 pl-[4rem]">
      <li>Work Authorization: Candidates must be legally authorized to work in the United States.</li>
      <li>Non-Compete and Confidentiality Agreement required upon hiring.</li>
      <li>On-Site Position in Altamonte Springs, FL.</li>
      <li>Availability for weekends or holidays if project demands.</li>
      <li>Drug and Alcohol-Free Policy: Pre-employment screening required.</li>
      <li>Background Check required, reviewed on a case-by-case basis.</li>
    </ul>

    <h2 className="text-3xl font-bold mt-8 mb-4">What We Offer</h2>
    <ul className="list-disc list-outside text-lg pl-[4rem]">
      <li>Competitive salary and benefits package.</li>
      <li>Opportunity to work with cutting-edge technology in a growing company.</li>
      <li>A collaborative and innovative work environment.</li>
      <li>Potential for profit-sharing and future ownership percentage opportunities.</li>
    </ul>

    <ApplicationForm careerTitle="Erlang Developer" />
  </div>
);

export default ErlangDeveloper;
