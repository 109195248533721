import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from './ThemeContext';
import Logo from './logo'; // Add this import

const Header = ({ className }) => {
  const { invertedBackground, invertedText, background, text, nonHomeTheme } = useTheme();
  const location = useLocation();
  const [isKeyboardNav, setIsKeyboardNav] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const isHomePage = location.pathname === '/';
  const currentTheme = isHomePage
    ? { invertedBackground, invertedText, background, text }
    : nonHomeTheme;

  const pages = [
    { path: '/', title: 'Home' },
    { path: '/about', title: 'About' },
    { path: '/team', title: 'Team' },
    { path: '/product', title: 'Product' },
    { path: '/investment', title: 'Investment' },
    { path: '/career', title: 'Careers' },
  ];

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Tab') {
        setIsKeyboardNav(true);
      }
    };

    const handleMouseDown = () => {
      setIsKeyboardNav(false);
    };

    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target) && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header 
      className={`w-full z-50 fixed top-0 left-0 right-0 ${className}`}
      style={{ 
        backgroundColor: currentTheme.invertedBackground,
        color: currentTheme.invertedText,
      }}
    >
      <style jsx>{`
        .hover-effect::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(107, 114, 128, 0.5);
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }
        .group:hover .hover-effect::before {
          opacity: 1;
        }
      `}</style>
      <div className='flex justify-between items-center h-full px-5'>
        <div className="flex items-center p-3 cursor-pointer" onClick={() => window.location.href = '/'}>
          <Logo 
            color={currentTheme.invertedText} 
            width="125px"
          />
        </div>

        {/* Mobile menu button */}
        <button
          className="md:hidden p-2 rounded-md transition-colors duration-200 ease-in-out"
          onClick={toggleMobileMenu}
          aria-label="Toggle mobile menu"
          style={{
            backgroundColor: isMobileMenuOpen ? currentTheme.background : 'transparent',
            color: isMobileMenuOpen ? currentTheme.text : currentTheme.invertedText,
          }}
        >
          {isMobileMenuOpen ? 'Close' : 'Menu'}
        </button>

        {/* Desktop navigation */}
        <nav className="hidden md:flex h-full items-center">
          {pages.map((page) => (
            <Link
              key={page.path}
              to={page.path}
              className={`group h-full flex items-center px-4 transition-colors duration-200 ease-in-out relative
                ${isKeyboardNav ? 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500' : 'focus:outline-none'}
              `}
              style={{
                backgroundColor: location.pathname === page.path ? currentTheme.background : 'transparent',
                color: location.pathname === page.path ? currentTheme.text : currentTheme.invertedText,
              }}
            >
              <span className="nav-text relative z-10">
                {page.title}
              </span>
              {location.pathname !== page.path && <span className="hover-effect absolute inset-0"></span>}
            </Link>
          ))}
        </nav>
      </div>

      {/* Mobile dropdown menu */}
      <nav
        ref={mobileMenuRef}
        className={`md:hidden absolute left-0 right-0 shadow-md ${
          isMobileMenuOpen ? 'block' : 'hidden'
        }`}
        style={{ 
          backgroundColor: currentTheme.invertedBackground,
          color: currentTheme.invertedText,
          top: className.split(' ').find(cls => cls.startsWith('h-')), // Use the header height for positioning
        }}
      >
        {pages.map((page) => (
          <Link
            key={page.path}
            to={page.path}
            className="group block px-4 py-2 transition-colors duration-200 ease-in-out relative"
            style={{
              backgroundColor: location.pathname === page.path ? currentTheme.background : 'transparent',
              color: location.pathname === page.path ? currentTheme.text : currentTheme.invertedText,
            }}
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <span className="relative z-10">{page.title}</span>
            {location.pathname !== page.path && <span className="hover-effect absolute inset-0"></span>}
          </Link>
        ))}
      </nav>
    </header>
  );
};

export default Header;
